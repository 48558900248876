<template>
  <div>
    <div class="container mt-5">
      <h1 class="title">Ingreso de Muestras</h1>

      <p class="has-text-grey mb-2">
        En esta opción se pueden registrar el ingreso de muestras al
        laboratorio.<br />
      </p>
      <p class="has-text-grey mb-2">
        Se pueden añadir nuevas muestras con el botón "Añadir Muestra", editar
        las muestras presionando el ícono
        <b-icon icon="pencil" size="is-small" />
        , y eliminar de la lista presionando el ícono
        <b-icon icon="delete" size="is-small" />
        .
      </p>
      <p class="has-text-grey mb-2">
        Una vez todos los registros sean válidos, será habilitado el botón
        Enviar, con el cual las muestras quedarán ingresadas.
      </p>

      <div class="has-text-right mt-5">
        <b-button
          type="is-primary"
          icon-left="plus-thick"
          @click="openSampleModal()"
        >
          Añadir Muestra
        </b-button>
      </div>
    </div>
    <div class="container mt-4">
      <b-notification
        :active.sync="showErrorMsg"
        type="is-danger"
        aria-close-label="Cerrar notificación"
      >
        {{ error }}
      </b-notification>

      <b-field v-if="samples.length > 0" grouped group-multiline>
        <div
          v-for="[name, column] in Object.entries(columns)"
          :key="name"
          class="control"
        >
          <b-checkbox v-model="column.visible">
            {{ column.title }}
          </b-checkbox>
        </div>
      </b-field>

      <div class="table-container">
        <b-table v-if="samples.length > 0" :data="samples">
          <b-table-column v-slot="props" label="#">
            {{ props.index + 1 }}
          </b-table-column>
          <b-table-column
            v-slot="props"
            :visible="columns.priority.visible"
            label="Prioridad"
            centered
          >
            {{ priorityNames[props.row.priority] }}
          </b-table-column>
          <b-table-column
            v-slot="props"
            :visible="columns.institution.visible"
            label="Institución"
            centered
          >
            {{ institutionNames[props.row.institutionId] }}
          </b-table-column>
          <b-table-column
            v-slot="props"
            :visible="columns.examTypeId.visible"
            label="Examen"
            centered
          >
            {{ examNames[props.row.examTypeId] }}
          </b-table-column>
          <b-table-column
            v-slot="props"
            :visible="columns.externalId.visible"
            label="N° Pet."
            numeric
            centered
          >
            {{ props.row.externalId }}
          </b-table-column>
          <b-table-column
            v-slot="props"
            :visible="columns.requestedBy.visible"
            label="Médico solicitante"
            centered
          >
            {{ props.row.requestedBy }}
          </b-table-column>
          <b-table-column
            v-slot="props"
            :visible="columns.idString.visible"
            label="RUN / Pas."
            centered
          >
            {{ props.row.idString }}
          </b-table-column>
          <b-table-column
            v-slot="props"
            :visible="columns.name.visible"
            label="Nombre"
            centered
          >
            {{ props.row.names }} {{ props.row.firstLastname }}
            {{ props.row.secondLastname }}
          </b-table-column>
          <b-table-column
            v-slot="props"
            :visible="columns.sex.visible"
            label="Sexo"
            centered
          >
            {{ props.row.sex }}
          </b-table-column>
          <b-table-column
            v-slot="props"
            :visible="columns.origin.visible"
            label="Procedencia"
            centered
          >
            {{ props.row.origin }}
          </b-table-column>
          <b-table-column
            v-slot="props"
            :visible="columns.sampleType.visible"
            label="Tipo de Muestra"
            centered
          >
            {{ props.row.sampleType }}
          </b-table-column>
          <b-table-column
            v-slot="props"
            :visible="columns.samplingDatetime.visible"
            label="Toma de Muestra"
            centered
          >
            {{ formatDatetime(props.row.samplingDatetime) }}
          </b-table-column>
          <b-table-column
            v-slot="props"
            :visible="columns.phone.visible"
            label="Teléfono"
            centered
          >
            {{ props.row.phone }}
          </b-table-column>
          <b-table-column
            v-slot="props"
            :visible="columns.address.visible"
            label="Dirección"
            centered
          >
            {{ props.row.address }}
          </b-table-column>
          <b-table-column
            v-slot="props"
            :visible="columns.email.visible"
            label="E-mail"
            centered
          >
            {{ props.row.email }}
          </b-table-column>
          <b-table-column v-slot="props" centered>
            <b-field grouped>
              <b-tooltip
                v-if="props.row.valid"
                type="is-light"
                label="Muestra Válida"
              >
                <b-icon type="is-success" icon="check" />
              </b-tooltip>
              <b-tooltip v-else type="is-light" label="Muestra inválida">
                <b-icon type="is-danger" icon="alert-octagon" />
              </b-tooltip>
              <b-tooltip type="is-light" label="Modificar">
                <b-button
                  size="is-small"
                  type="is-text"
                  @click="openSampleModal(props.row)"
                >
                  <b-icon icon="pencil" />
                </b-button>
              </b-tooltip>
              <b-tooltip type="is-light" label="Eliminar">
                <b-button
                  size="is-small"
                  type="is-text"
                  @click="deleteSample(props.index)"
                >
                  <b-icon icon="delete" />
                </b-button>
              </b-tooltip>
            </b-field>
          </b-table-column>
        </b-table>
      </div>
      <b-field v-if="samples.length > 0" position="is-right" grouped>
        <b-field>
          <b-button
            :disabled="!allSamplesValid"
            type="is-primary"
            @click="preSubmit"
          >
            Enviar
          </b-button>
        </b-field>
      </b-field>
    </div>
  </div>
</template>

<script>
  import SampleInputForm from "@/components/samples/SampleInputForm.vue";
  import { routes } from "@/api";
  import { datepickerOptions, formatDateTime } from "@/utils";

  export default {
    name: "SamplesInput",
    // eslint-disable-next-line vue/no-unused-components
    components: { SampleInputForm },
    data() {
      return {
        samples: [],
        columns: {
          priority: { title: "Prioridad", visible: true },
          institution: { title: "Institución", visible: true },
          origin: { title: "Procedencia", visible: false },
          examTypeId: { title: "Examen", visible: true },
          externalId: { title: "N° Pet.", visible: false },
          idString: { title: "RUN / Pas.", visible: true },
          name: { title: "Nombre", visible: true },
          sex: { title: "Sexo", visible: true },
          sampleType: { title: "Tipo de Muestra", visible: false },
          samplingDatetime: { title: "Toma de Muestra", visible: true },
          requestedBy: { title: "Médico solicitante", visible: false },
          phone: { title: "Teléfono", visible: false },
          address: { title: "Dirección", visible: false },
          email: { title: "E-mail", visible: false },
        },
        priorityNames: {
          normal: "Normal",
          urgent: "Urgente",
        },
        datepickerOptions,
        institutions: [],
        categories: [],
        examTypes: [],
        loading: false,
        showErrorMsg: false,
        error: "",
      };
    },
    computed: {
      allSamplesValid() {
        return this.samples.every((x) => x.valid);
      },
      userHasInstitution() {
        return this.$store.state.user.institution !== null;
      },
      examNames() {
        return this.examTypes.reduce((acc, x) => {
          acc[x.id] = x.shortName;
          return acc;
        }, {});
      },
      institutionNames() {
        return this.institutions.reduce((acc, x) => {
          acc[x.id] = x.name;
          return acc;
        }, {});
      },
    },
    created() {
      this.axios.get(routes.getInstitutions).then((reponse) => {
        this.institutions = reponse.data.results;
      });
      this.loadExamTypes();
      this.loadSampleCategories();
    },
    methods: {
      openSampleModal(sample) {
        this.$buefy.modal.open({
          parent: this,
          component: SampleInputForm,
          hasModalCard: true,
          trapFocus: true,
          props: {
            enteredSamples: this.samples,
            sample: sample ? sample : {},
            showInstructions: this.showInstructions,
            examTypes: this.examTypes,
            sampleCategories: this.categories,
            institutions: this.institutions,
          },
          events: {
            "update:show-instructions": (value) => {
              this.showInstructions = value;
            },
            save: (value, modal) => {
              if (sample) {
                sample = value;
              } else {
                this.samples.push(value);
              }
              modal.close();
            },
            cancel: (modal) => {
              modal.close();
            },
          },
        });
      },
      deleteSample(idx) {
        this.samples.splice(idx, 1);
      },
      loadExamTypes() {
        this.axios
          .get(routes.getExamTypes)
          .then((response) => {
            this.examTypes = response.data.results.filter(
              (x) => x.shortName !== "SARS-CoV-2"
            );
          })
          .catch((error) => {
            console.log(error);
          });
      },
      loadSampleCategories() {
        this.axios
          .get(routes.getSampleCategories)
          .then((response) => {
            this.categories = response.data.results;
          })
          .catch((error) => {
            console.log(error);
          });
      },
      preSubmit() {
        if (this.userHasInstitution) {
          this.$buefy.dialog.confirm({
            message:
              "¿Está seguro de registrar estas muestras? Una vez guardadas ya no será posible modificarlas.",
            confirmText: "OK",
            cancelText: "Cancelar",
            type: "is-success",
            onConfirm: () => this.submitData(),
          });
        } else {
          this.submitData();
        }
      },
      submitData() {
        this.axios
          .post(routes.inputNonCovidSamples, {
            samples: this.samples.map((x) => ({
              ...x,
              samplingDatetime: this.$date(x.samplingDatetime).format(
                "YYYY-MM-DD HH:mm"
              ),
              dateOfBirth: this.$date(x.dateOfBirth).format("YYYY-MM-DD"),
            })),
          })
          .then((response) => {
            if (response.data.status === "OK") {
              this.$buefy.toast.open({
                duration: 2000,
                message: "Datos ingresados correctamente",
                type: "is-success",
              });
              this.samples = [];
            } else {
              throw new Error(response.data.message);
            }
          })
          .catch((e) => {
            this.$buefy.toast.open({
              duration: 3000,
              message: `Ocurrió un error: ${e}`,
              type: "is-danger",
            });
            this.$log.error(e);
          });
      },
      formatDatetime(datetime) {
        return formatDateTime(datetime);
      },
    },
  };
</script>

<style scoped></style>
