<template>
  <div class="container mt-4">
    <div class="is-centered">
      <h1 class="title">Ingreso mapeo placa de extracción</h1>
      <p class="has-text-grey mb-3">
        En esta opción se puede registrar información acerca de placas de
        extración de muestras.
      </p>
      <p class="has-text-grey mb-3">
        Complete los campos del formulario y carge archivo excel con el mapeo de
        muestras dentro de la placa. Utilice la siguiente
        <a @click="getInputTemplate">[plantilla]</a> de excel como base para
        ingresar y subir el mapeo de la placa al sistema.
      </p>
    </div>
    <div>
      <b-loading :active="loading" :is-full-page="false" />
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <form id="plateForm" @submit.prevent>
          <b-field grouped group-multiline>
            <b-field expanded>
              <b-input-with-validation
                v-model="formData.id_string"
                rules="required|alpha_num|unique"
                name="idString"
                label="Código placa"
                class="field"
                :debounce="500"
              />
            </b-field>
            <b-field expanded>
              <b-input-with-validation
                v-model="formData.batch"
                rules="required|alpha_num"
                name="batch"
                label="Lote"
                class="field"
              />
            </b-field>

            <b-select-with-validation
              v-model="formData.extraction_equipment_id"
              :rules="{
                required: true,
                oneOf: getValues(formOptions.equipment),
              }"
              name="equipment"
              label="Equipo"
              class="field"
            >
              <option
                v-for="equip in formOptions.equipment"
                :key="equip.value"
                :value="equip.value"
              >
                {{ equip.option }}
              </option>
            </b-select-with-validation>
            <validation-provider
              v-slot="{ errors, valid }"
              name="creationDate"
              :rules="{
                required: true,
              }"
            >
              <b-field
                label="Fecha y hora de inicio"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :message="errors"
              >
                <b-datetimepicker
                  v-model="formData.creation_datetime"
                  placeholder="DD-MM-AAAA HH:SS"
                  icon="calendar-today"
                  :datepicker="datepickerOptions"
                  :timepicker="{ hourFormat: '24' }"
                  :datetime-parser="parseDatetime"
                  editable
                  append-to-body
                  class="field"
                />
              </b-field>
            </validation-provider>

            <validation-provider
              v-slot="{ errors, valid }"
              name="expirationDate"
              :rules="{
                required: true,
              }"
            >
              <b-field
                label="Fecha vencimiento"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :message="errors"
              >
                <b-datepicker
                  v-model="formData.expiration_datetime"
                  placeholder="DD-MM-AAAA"
                  icon="calendar-today"
                  :first-day-of-week="datepickerOptions.firstDayOfWeek"
                  :day-names="datepickerOptions.dayNames"
                  :datetime-parser="parseDatetime"
                  editable
                  append-to-body
                  class="field"
                />
              </b-field>
            </validation-provider>
          </b-field>

          <b-field grouped group-multiline>
            <b-select-with-validation
              v-model="formData.extraction_kit_id"
              :rules="{ required: true, oneOf: getValues(formOptions.kits) }"
              name="kit"
              label="Kit"
              class="field"
            >
              <option
                v-for="kit in formOptions.kits"
                :key="kit.value"
                :value="kit.value"
              >
                {{ kit.option }}
              </option>
            </b-select-with-validation>

            <b-select-with-validation
              v-model="formData.internal_control_id"
              :rules="{
                required: true,
                oneOf: getValues(formOptions.controls),
              }"
              name="control"
              label="Sonda de Extracción"
              class="field"
            >
              <option
                v-for="control in formOptions.controls"
                :key="control.value"
                :value="control.value"
              >
                {{ control.option }}
              </option>
            </b-select-with-validation>

            <b-select-with-validation
              v-model="formData.operator_id"
              :rules="{
                required: true,
                oneOf: getValues(formOptions.users),
                distinct: formData.reviewer_id,
              }"
              name="operator"
              label="Operador"
              class="field"
            >
              <option
                v-for="operator in formOptions.users"
                :key="operator.value"
                :value="operator.value"
              >
                {{ operator.option }}
              </option>
            </b-select-with-validation>

            <b-select-with-validation
              v-model="formData.reviewer_id"
              :rules="{
                required: true,
                oneOf: getValues(formOptions.users),
                distinct: formData.operator_id,
              }"
              name="reviewer"
              label="Revisor"
              class="field"
            >
              <option
                v-for="reviewer in formOptions.users"
                :key="reviewer.value"
                :value="reviewer.value"
              >
                {{ reviewer.option }}
              </option>
            </b-select-with-validation>

            <b-field label="Incidentes">
              <div class="pt-2">
                <b-radio
                  v-model="hasIncidents"
                  :native-value="true"
                  type="is-danger"
                >
                  Si
                </b-radio>
                <b-radio
                  v-model="hasIncidents"
                  :native-value="false"
                  type="is-info"
                >
                  No
                </b-radio>
              </div>
            </b-field>
          </b-field>

          <div class="mr-3">
            <b-input-with-validation
              v-if="hasIncidents"
              v-model="formData.incidents"
              rules="max:256|required"
              maxlength="256"
              name="incidents"
              label="Incidentes"
              class="field"
              :debounce="500"
            />

            <b-input-with-validation
              v-model="formData.observations"
              type="textarea"
              rules="max:256"
              maxlength="256"
              name="observations"
              label="Observaciones"
              class="field"
              :debounce="500"
            />
          </div>

          <validation-provider
            v-slot="{ errors, valid }"
            rules="required|fileSize:500|validFile"
            name="plateFile"
          >
            <label class="label">Archivo</label>
            <b-field id="plateFile" class="file is-light" name="file">
              <b-upload
                v-model="formData.file"
                name="plateFile"
                class="file-label"
                accept=".xls,.xlsx"
              >
                <span class="file-cta">
                  <b-icon class="file-icon" icon="upload"></b-icon>
                  <span> Cargar layout (.xls, .xlsx) </span>
                </span>
                <span
                  v-if="formData.file"
                  class="file-name"
                  :class="valid ? 'valid-border' : 'invalid-border'"
                >
                  {{ formData.file.name }}
                </span>
              </b-upload>
            </b-field>

            <span class="file-error help is-danger">{{ errors[0] }}</span>
            <template v-if="valid && plateData">
              <h1 class="title small">Mapeo por cargar</h1>
              <p class="has-text-grey mb-3">
                En la siguiente tabla se puede visualizar el mapeo de la placa a
                ingresar. En verde se encuentran valores considerados como
                muestras de covid19.
              </p>
              <plate-table :table-data="plateData"></plate-table>
            </template>
          </validation-provider>
          <hr />

          <p class="control">
            <b-button
              :loading="submitLoading"
              class="button is-primary"
              @click="handleSubmit(submit)"
            >
              Enviar
            </b-button>
          </p>
        </form>
      </validation-observer>

      <template v-if="!submitSuccess">
        <b-message
          id="message"
          title="Error"
          type="is-danger"
          aria-close-label="Close message"
        >
          <p v-for="(message, i) in submitMessage" :key="i">
            {{ message }}
          </p>
        </b-message>
      </template>
    </div>
  </div>
</template>

<script>
  import BInputWithValidation from "@/components/inputs/BInputWithValidation.vue";
  import BSelectWithValidation from "@/components/inputs/BSelectWithValidation.vue";
  import PlateTable from "@/components/plates/PlateLayoutTable.vue";
  import { datepickerOptions } from "@/utils";
  import { routes } from "@/api";
  import { saveAs } from "file-saver";
  import { extend, ValidationObserver, ValidationProvider } from "vee-validate";

  export default {
    name: "ExtractionPlateInput",
    components: {
      BInputWithValidation,
      BSelectWithValidation,
      ValidationProvider,
      ValidationObserver,
      PlateTable,
    },
    data() {
      return {
        datepickerOptions,
        submitLoading: false,
        submitMessage: null,
        submitSuccess: true,
        hasIncidents: false,
        loading: false,
        plateData: null,
        formData: {
          id_string: "",
          batch: "",
          extraction_equipment_id: "",
          internal_control_id: "",
          extraction_kit_id: "",
          expiration_datetime: null,
          creation_datetime: null,
          operator_id: "",
          reviewer_id: "",
          incidents: "",
          observations: "",
          file: null,
        },
        formOptions: {
          kits: [],
          users: [],
          controls: [],
          equipment: [],
        },
        fileErrorMessage: "",
      };
    },
    created() {
      this.getFormOptions();
    },
    mounted() {
      extend("unique", {
        validate: this.availPlateId,
        message: "Código de placa se encuentra ingresado",
      });

      extend("validFile", {
        validate: this.validateFile,
        message: () => {
          return `Error formato archivo excel: ${this.fileErrorMessage}`;
        },
      });
    },
    methods: {
      submit() {
        this.submitLoading = true;
        this.submitSuccess = true;
        let formData = this.parseFormData();
        this.axios
          .post(routes.inputExtractionPlateLayout, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response) => {
            if (response.data.status === "error") {
              this.$buefy.toast.open({
                duration: 2000,
                message: "Datos ingresados",
              });
              this.submitSuccess = false;
              this.submitMessage = response.data.message.error;
            } else {
              this.$buefy.toast.open({
                duration: 2000,
                message: "Datos ingresados",
                type: "is-success",
              });
            }
          })
          .catch(() => {
            this.submitSuccess = false;
            this.submitMessage = "Error de conexión con el servidor";
          })
          .finally(() => {
            this.clearForm();
            this.submitLoading = false;
          });
      },
      parseFormData() {
        let formData = new FormData();
        for (let [field, value] of Object.entries(this.formData)) {
          if (value instanceof Date) {
            formData.append(
              field,
              this.$date(value).format("YYYY-MM-DD HH:mm")
            );
          } else {
            formData.append(field, value);
          }
        }
        return formData;
      },
      getFormOptions() {
        this.loading = true;
        this.axios
          .get(routes.getExtractionPlateOptions)
          .then((response) => {
            this.formOptions = response.data.results;
          })
          .catch(() => {
            this.alertError();
          })
          .finally(() => {
            this.loading = false;
          });
      },
      getValues(options) {
        return options.map((x) => x.value);
      },
      parseDatetime(datetime) {
        return this.$date(datetime, "DD-MM-YYYY HH:mm").toDate();
      },
      clearForm() {
        this.formData = {
          id_string: "",
          ligation_batch: "",
          extraction_equipment_id: "",
          internal_control_id: "",
          extraction_kit_id: "",
          expiration_datetime: null,
          creation_datetime: null,
          operator_id: "",
          reviewer_id: "",
          observations: "",
          incidents: "",
          file: null,
        };
        this.$nextTick(() => this.$refs.observer.reset());
      },
      async availPlateId() {
        const response = await this.axios.get(
          routes.availExtractionPlateId.replace(
            ":id",
            String(this.formData.id_string)
          )
        );
        return response.data.available;
      },
      async validateFile() {
        let formData = new FormData();
        formData.append("file", this.formData.file);
        const response = await this.axios.post(
          routes.standardPlateFileValidator,
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        );
        this.plateData = response.data.parseData;
        this.fileErrorMessage = response.data.message;

        return response.data.valid;
      },
      alertError() {
        this.$buefy.dialog.alert({
          title: "Error",
          message: "Error de conexión con el servidor, intente denuevo",
          type: "is-danger",
          hasIcon: true,
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      },
      getInputTemplate() {
        this.axios
          .get(routes.plateLayoutInputTemplate, {
            responseType: "blob",
          })
          .then((response) => {
            const blob = new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            saveAs(blob, `CADI_plantilla_ingreso_mapeo.xlsx`);
          })
          .catch(() => {
            this.errorAlert("Error al descargar datos, intente denuevo");
          });
      },
    },
  };
</script>

<style scoped>
  hr {
    margin-top: 0.7rem;
    margin-bottom: 0.7rem;
  }
  #message {
    margin-top: 0.7rem;
  }
  .small {
    font-size: 1.3rem;
    margin-top: 1.5rem;
  }
  .valid-border {
    border-color: hsl(141, 53%, 53%);
  }
  .invalid-border {
    border-color: hsl(348, 100%, 61%);
  }
  .file-name {
    border-width: 1px 1px 1px 1px;
  }
</style>
