<template>
  <div class="container mt-5">
    <h1 class="title">Muestras Pendientes no COVID</h1>

    <p class="has-text-grey mb-4">
      Esta sección permite visualizar las muestras que aún no han sido
      procesadas.
    </p>

    <b-field>
      <b-radio-button
        v-model="sampleFilter"
        native-value="diagnosis"
        type="is-primary"
      >
        <span>Diagnóstico</span>
      </b-radio-button>

      <b-radio-button
        v-model="sampleFilter"
        native-value="research"
        type="is-primary"
      >
        <span>Investigación</span>
      </b-radio-button>

      <b-radio-button
        v-model="sampleFilter"
        native-value="all"
        type="is-primary"
      >
        <span>Todas</span>
      </b-radio-button>
    </b-field>

    <div class="columns place-center">
      <div class="column">
        <b-table
          v-if="formattedSamples.length > 0 || loading"
          :data="formattedSamples"
          :checked-rows.sync="selectedSamples"
          :loading="loading"
          hoverable
          narrowed
          checkable
        >
          <b-table-column v-slot="props" label="Prioridad" centered>
            {{ props.row.priority }}
          </b-table-column>
          <b-table-column v-slot="props" label="ID" centered>
            {{ props.row.sampleId }}
          </b-table-column>
          <b-table-column v-slot="props" label="Tipo de Examen" centered>
            {{ props.row.examType }}
          </b-table-column>
          <b-table-column
            v-slot="props"
            field="category"
            label="Categoría"
            centered
          >
            {{ props.row.sampleCategory }}
          </b-table-column>
          <b-table-column
            v-slot="props"
            field="institution"
            label="Institución"
            centered
          >
            {{ props.row.institution }}
          </b-table-column>
          <b-table-column v-slot="props" label="RUN / Pas." centered>
            {{ props.row.idString }}
          </b-table-column>
          <b-table-column v-slot="props" label="Nombre" centered>
            {{ props.row.name }}
          </b-table-column>
          <b-table-column v-slot="props" label="Ingreso" centered>
            {{ formatDateTime(props.row.receptionDatetime) }}
          </b-table-column>
          <b-table-column v-slot="props" centered>
            <b-tooltip label="Rechazar Muestra" type="is-light">
              <b-button
                type="is-text"
                size="is-small"
                @click="openRejectModal(props.row)"
              >
                <b-icon
                  icon="flask-empty-remove-outline"
                  custom-size="mdi-18px"
                  type="is-danger"
                />
              </b-button>
            </b-tooltip>
            <b-tooltip label="Eliminar Muestra" type="is-light">
              <b-button
                type="is-text"
                size="is-small"
                @click="openDeleteModal(props.row)"
              >
                <b-icon
                  icon="delete-outline"
                  custom-size="mdi-18px"
                  type="is-danger"
                />
              </b-button>
            </b-tooltip>
          </b-table-column>
          <template #empty>
            <div class="table-empty" />
          </template>
        </b-table>
        <b-message v-else> No hay resultados para mostrar.</b-message>
        <div v-if="selectedSamples.length > 0" class="mt-3 has-text-right">
          <b-button
            type="is-success"
            icon-left="check"
            @click="openProcessModal"
          >
            Registrar como procesadas
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { routes } from "@/api";
  import { formatDateTime } from "@/utils";
  import RejectNonCovidSampleModal from "../../components/samples/RejectNonCovidSampleModal.vue";

  export default {
    name: "PendingNonCovidSamples",
    data() {
      return {
        samples: [],
        selectedSamples: [],
        rejectionOptions: [],
        loading: false,
        sampleFilter: "diagnosis",
        columnLabels: {
          priority: "Prioridad",
          tipoExamen: "Tipo de Examen",
          sampleId: "Muestra",
          institution: "Institución",
          idString: "RUN",
          name: "Nombre",
          samplingDatetimeFmt: "Toma de Muestra",
          receptionDatetimeFmt: "Recepción",
        },
      };
    },
    computed: {
      formattedSamples() {
        if (this.sampleFilter === "diagnosis") {
          return this.samples
            .filter((x) =>
              ["Diagnóstico", "Diagnóstico gratis"].includes(x.sampleCategory)
            )
            .map(this.formatSample);
        } else if (this.sampleFilter === "research") {
          return this.samples
            .filter(
              (x) =>
                !["Diagnóstico", "Diagnóstico gratis"].includes(
                  x.sampleCategory
                )
            )
            .map(this.formatSample);
        } else {
          return this.samples.map(this.formatSample);
        }
      },
    },
    watch: {
      sampleFilter() {
        this.selectedSamples = [];
      },
    },
    created() {
      this.loadSamples();
      this.loadRejectionOptions();
    },
    methods: {
      loadSamples() {
        this.loading = true;
        this.axios
          .get(routes.pendingNonCovidSamples)
          .then((response) => {
            this.samples = response.data.results;
          })
          .catch((e) => {
            this.$log.error(e);
          })
          .finally(() => {
            this.loading = false;
          });
      },
      loadRejectionOptions() {
        this.axios
          .get(routes.getRejectionReasons)
          .then((response) => {
            this.rejectionOptions = response.data.results;
          })
          .catch((e) => {
            this.$log.error(e);
          });
      },
      formatSample(sample) {
        return {
          ...sample,
          name: `${sample.names} ${sample.firstLastname} ${sample.secondLastname}`,
          externalId: sample.externalId
            ? `${sample.institutionPrefix}${sample.externalId}`
            : null,
          samplingDatetimeFmt: this.$date(sample.samplingDatetime).format(
            "DD-MM-YYYY HH:mm"
          ),
          receptionDatetimeFmt: this.$date(sample.receptionDatetime).format(
            "DD-MM-YYYY HH:mm"
          ),
        };
      },
      formatDateTime(datetime) {
        return formatDateTime(datetime);
      },
      openProcessModal() {
        this.$buefy.dialog.confirm({
          title: "Procesamiento de muestras",
          message:
            `Se registrarán como procesadas las siguiente muestras:<br><br>` +
            this.selectedSamples
              .map((x) => `${x.sampleId} - ${x.examType}`)
              .join("<br>"),
          confirmText: "Confirmar",
          cancelText: "Cancelar",
          type: "is-success",
          hasIcon: true,
          width: 1000,
          trapFocus: true,
          onConfirm: () =>
            this.processSample(this.selectedSamples.map((x) => x.sampleId)),
        });
      },
      openRejectModal(sample) {
        this.$buefy.modal.open({
          parent: this,
          component: RejectNonCovidSampleModal,
          props: {
            sample,
            rejectionOptions: this.rejectionOptions,
          },
          events: {
            saved: (modal) => {
              modal.close();
              this.loadSamples();
            },
          },
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
        });
      },
      openDeleteModal(sample) {
        this.$buefy.dialog.confirm({
          title: "Eliminación de muestra",
          message:
            `<strong>Se eliminará la siguiente muestra:</strong><br><br>` +
            `ID: ${sample.sampleId}<br>` +
            `Institución: ${sample.institution}<br>` +
            `Paciente: ${sample.names} ${sample.firstLastname} ${sample.secondLastname}<br>` +
            `Tipo de examen: ${sample.examType}<br><br>` +
            `<strong>Esta acción no se puede deshacer.</strong>`,
          confirmText: "Confirmar",
          cancelText: "Cancelar",
          type: "is-danger",
          hasIcon: true,
          width: 1000,
          trapFocus: true,
          onConfirm: () => this.deleteSamples(sample.sampleId),
        });
      },
      processSample(sampleIds) {
        const data = {
          samples: sampleIds.map((x) => ({ sample_id: x })),
        };

        this.axios.post(routes.processNonCovidSamples, data).then(() => {
          this.$buefy.toast.open({
            message: "Muestras procesadas exitosamente",
            type: "is-success",
          });
          this.selectedSamples = [];
          this.loadSamples();
        });
      },
      deleteSamples(sampleId) {
        const data = {
          samples: [{ sample_id: sampleId }],
        };

        this.axios.post(routes.deleteNonCovidSamples, data).then(() => {
          this.$buefy.toast.open({
            message: "Muestra eliminada exitosamente",
            type: "is-success",
          });
          this.loadSamples();
        });
      },
    },
  };
</script>

<style scoped>
  .place-center {
    place-content: center;
  }
</style>
