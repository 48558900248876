<template>
  <div class="container mt-4">
    <b-loading v-model="loading" is-full-page></b-loading>
    <h1 class="title">
      Detalles celda de secuenciación genómica Nº{{ cellId }}
      <b-button type="is-light" @click="openEditModal">
        <b-icon type="is-dark" icon="pen"></b-icon>
      </b-button>
    </h1>
    <div class="columns is-centered pb-0">
      <div class="column is-three-fifths-fullhd is-four-fifths-desktop">
        <table class="table is-fullwidth">
          <tbody>
            <tr>
              <th colspan="4" class="has-text-centered">Experimento</th>
            </tr>
            <tr>
              <td class="valign-mid">
                <strong>Número:</strong>
              </td>
              <td class="valign-mid">{{ cellData.experimentId }}</td>
              <td class="valign-mid">
                <strong>Total muestras:</strong>
              </td>
              <td class="valign-mid">
                {{ cellData.samples }}
              </td>
            </tr>
            <tr>
              <td class="valign-mid">
                <strong>Fecha y hora de inicio:</strong>
              </td>
              <td class="valign-mid">
                {{ getDatetime(cellData.startDatetime) }}
              </td>
              <td class="valign-mid">
                <strong>Fecha y hora de termino:</strong>
              </td>
              <td class="valign-mid">
                {{ getDatetime(cellData.finishDatetime) }}
              </td>
            </tr>
            <tr>
              <th colspan="4" class="has-text-centered">Secuenciador</th>
            </tr>
            <tr>
              <td class="valign-mid">
                <strong>Equipo:</strong>
              </td>
              <td class="valign-mid">{{ cellData.seqEquipment }}</td>
              <td class="valign-mid">
                <strong>Código:</strong>
              </td>
              <td class="valign-mid">{{ cellData.seqEquipmentInternalId }}</td>
            </tr>
            <tr>
              <th colspan="4" class="has-text-centered">Celda</th>
            </tr>
            <tr>
              <td class="valign-mid">
                <strong>Número poros activos:</strong>
              </td>
              <td class="valign-mid">{{ cellData.pores }}</td>
              <td class="valign-mid">
                <strong>Código:</strong>
              </td>
              <td class="valign-mid">{{ cellData.cellId }}</td>
            </tr>
            <tr>
              <th colspan="4" class="has-text-centered">Kits</th>
            </tr>
            <tr>
              <td class="valign-mid">
                <strong>Ligación:</strong>
              </td>
              <td class="valign-mid">{{ cellData.ligationKit }}</td>
              <td class="valign-mid">
                <strong>Barcode:</strong>
              </td>
              <td class="valign-mid">
                {{ cellData.barcodeKit }}
              </td>
            </tr>
            <tr>
              <td class="valign-mid">
                <strong>Lote kit ligación:</strong>
              </td>
              <td class="valign-mid">
                {{ cellData.ligationBatch }}
              </td>
              <td class="valign-mid">
                <strong>Lote kit barcode:</strong>
              </td>
              <td class="valign-mid">
                {{ cellData.barcodeBatch }}
              </td>
            </tr>
            <tr>
              <th colspan="4" class="has-text-centered">Controles</th>
            </tr>
            <tr>
              <td class="valign-mid">
                <strong>Blanco de amplificación válido:</strong>
              </td>
              <td class="valign-mid">
                {{ bool2str[cellData.amplificationValid] }}
              </td>
              <td class="valign-mid">
                <strong>Electroforesis válido:</strong>
              </td>
              <td class="valign-mid">
                {{ bool2str[cellData.electrophoresisValid] }}
              </td>
            </tr>
            <tr>
              <td class="valign-mid">
                <strong>Control cuantitativo válido:</strong>
              </td>
              <td class="valign-mid" colspan="3">
                {{ bool2str[cellData.quantitativeControlValid] }}
              </td>
            </tr>
            <tr>
              <th colspan="4" class="has-text-centered">Personal</th>
            </tr>
            <tr>
              <td class="valign-mid">
                <strong>Operador:</strong>
              </td>
              <td class="valign-mid">{{ cellData.operator }}</td>
              <td class="valign-mid">
                <strong>Revisor:</strong>
              </td>
              <td class="valign-mid">{{ cellData.reviewer }}</td>
            </tr>
            <template v-if="cellData.observations">
              <tr>
                <th colspan="4" class="has-text-success has-text-centered">
                  Observaciones
                </th>
              </tr>
              <tr>
                <td colspan="4" class="has-text-centered">
                  {{ cellData.observations }}
                </td>
              </tr>
            </template>
            <template v-if="cellData.incidents">
              <tr>
                <th colspan="4" class="has-text-danger-dark has-text-centered">
                  Incidentes
                </th>
              </tr>
              <tr>
                <td colspan="4" class="has-text-centered">
                  {{ cellData.incidents }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <p class="has-text-grey mb-2">Tabla con muestras secuenciadas:</p>
    <div>
      <sample-sequencing-table :table-data="tableData" />
    </div>
    <div class="columns is-centered mt-5">
      <b-button
        type="is-primary"
        icon-left="file-excel"
        :loading="sheetGenerating"
        @click="downloadData()"
      >
        Descargar datos de celda</b-button
      >
    </div>
  </div>
</template>

<script>
  import { routes } from "@/api";
  import { saveAs } from "file-saver";
  import SampleSequencingTable from "@/components/sequencing/SampleSequencingTable.vue";
  import SeqCellEditModal from "@/components/sequencing/SeqCellEditModal.vue";

  export default {
    name: "SeqCellDetails",
    components: { SampleSequencingTable },
    data() {
      return {
        sheetGenerating: false,
        loading: false,
        cellId: this.$route.params.id,
        cellData: {},
        tableData: [],
        bool2str: { true: "Sí", false: "No" },
      };
    },
    created() {
      this.loadData();
    },
    methods: {
      loadData() {
        this.loading = true;
        this.axios
          .get(routes.sequencingCellDetails.replace(/:id/, this.cellId))
          .then((response) => {
            this.cellData = response.data.results.recordData;
            this.tableData = response.data.results.mapping;
          })
          .catch(() => {
            this.errorAlert();
          })
          .finally(() => {
            this.loading = false;
          });
      },
      getDate(datetime) {
        return this.$date(datetime).format("DD/MM/YYYY");
      },
      getDatetime(datetime) {
        return this.$date(datetime).format("DD/MM/YY HH:mm");
      },
      errorAlert(message = null) {
        if (!message) {
          message = "Error de conexión con el servidor, intente denuevo";
        }
        this.$buefy.dialog.alert({
          title: "Error",
          message: message,
          type: "is-danger",
          hasIcon: true,
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      },
      downloadData() {
        this.sheetGenerating = true;
        this.axios
          .get(routes.getSequencingCellSheet.replace(/:id/, this.cellId), {
            responseType: "blob",
          })
          .then((response) => {
            const blob = new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            saveAs(blob, `CADIUMAG_celda_secuenciacón_${this.cellId}.xlsx`);
          })
          .catch(() => {
            this.errorAlert();
          })
          .finally(() => {
            this.sheetGenerating = false;
          });
      },
      openEditModal() {
        this.$buefy.modal.open({
          parent: this,
          component: SeqCellEditModal,
          props: {
            cellData: this.cellData,
          },
          events: {
            saved: (modal) => {
              this.loadData();
              modal.close();
              this.$buefy.toast.open({
                duration: 2000,
                message: "Cambios guardados correctamente",
                type: "is-success",
              });
            },
          },
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
        });
      },
    },
  };
</script>

<style scoped>
  th {
    background-color: rgba(230, 230, 230, 0.3);
  }
</style>
