<template>
  <div>
    <div class="container mt-5">
      <h1 class="title">Validación de Resultados</h1>

      <p class="has-text-grey">
        Esta pantalla permite validar la conclusión automática generada por la
        plataforma.<br />
        Las muestras marcadas para repetir no serán incluídas en los informes y
        será posible asociar nuevos resultados al número de muestra.<br />
        Se indican en color <span class="has-text-success">verde</span> los
        valores que pasaron en filtro y en
        <span class="has-text-danger">rojo</span> los que no.
      </p>
    </div>
    <div class="container is-fluid">
      <div class="table-container">
        <div v-if="resultsData.length > 0" class="container mt-3">
          <b-button
            type="is-warning is-light is-outlined"
            icon-left="undo"
            @click="undoResultUpload"
          >
            Deshacer Carga de Resultados
          </b-button>
        </div>
        <table
          v-if="resultsData.length > 0 || loading"
          class="table is-fullwidth is-hoverable mt-4"
        >
          <thead>
            <tr>
              <th rowspan="2" class="has-text-centered has-text-bottom">
                Muestra
              </th>
              <th rowspan="2" class="has-text-centered has-text-bottom">
                <b-tooltip
                  type="is-light"
                  multilined
                  label="Última conclusión validada del paciente asociado a la muestra, no disponible para pools"
                >
                  Histórico
                </b-tooltip>
              </th>
              <th
                v-for="type in targetTypes"
                :key="type"
                :colspan="resultsFields.length / targetTypes.length"
                :class="
                  targetTypes.length === 1
                    ? 'has-text-centered is-borderless'
                    : 'has-text-centered'
                "
              >
                {{ type }}
              </th>
              <th rowspan="2" class="has-text-centered has-text-bottom">
                Conclusión
              </th>
              <th rowspan="2" class="has-text-centered has-text-bottom">
                Validación
              </th>
              <th rowspan="2" class="has-text-centered has-text-bottom">
                ¿Repetir Exp?
              </th>
              <th rowspan="2" class="has-text-centered has-text-bottom">
                Obs.
              </th>
              <th rowspan="2"></th>
            </tr>
            <tr>
              <th
                v-for="target in resultsFields"
                :key="target"
                class="has-text-centered"
              >
                {{ target.includes("_") ? target.split("_")[1] : target }}
              </th>
            </tr>
          </thead>
          <div v-if="loading" class="empty-tbody">
            <b-loading :active="loading" :is-full-page="false" />
          </div>
          <tbody v-else>
            <tr v-for="(result, i) in resultsData" :key="result.sampleId">
              <td class="has-text-centered has-text-vcentered">
                <template v-if="result.sampleId">
                  {{ result.sampleId }}
                  <span v-if="result.isRepetition">[R]</span>
                </template>
                <template v-else>
                  <b-tooltip
                    position="is-right"
                    :label="formatPoolSamples(result.poolSamples)"
                    type="is-light"
                    class="breakedline"
                    size="is-small"
                    multilined
                    dashed
                  >
                    {{ formatPoolId(result.poolId) }}
                  </b-tooltip>
                </template>
              </td>
              <td class="has-text-centered has-text-vcentered">
                <template v-if="result.history">
                  <b-tooltip
                    type="is-light"
                    :label="
                      'Fecha: ' +
                      formatDatetime(result.history.conclusionDatetime)
                    "
                  >
                    <b-tag
                      :type="tagTypes[result.history.conclusion]"
                      size="is-medium"
                    >
                      {{ conclusionOptions[result.history.conclusion] }}
                    </b-tag>
                  </b-tooltip>
                </template>
                <span v-else-if="result.sampleId"> Sin resultados previos</span>
                <span v-else>-</span>
              </td>
              <td
                v-for="field in resultsFields"
                :key="field"
                class="has-text-centered has-text-vcentered"
              >
                <span
                  :class="
                    getValueTextClass(
                      field.replace(' ', ''),
                      result.ctValues[field.replace(' ', '')]
                    )
                  "
                >
                  {{ formatFloat(result.ctValues[field.replace(" ", "")]) }}
                </span>
              </td>
              <td class="has-text-centered has-text-vcentered">
                <b-tag :type="tagTypes[result.conclusion]" size="is-medium">
                  {{ conclusionOptions[result.conclusion] }}
                </b-tag>
              </td>
              <td class="has-text-centered has-text-vcentered">
                <b-field grouped position="is-centered">
                  <b-select v-model="inputValues[i].validation">
                    <option
                      v-for="(name, value) of inputValues[i].sampleId
                        ? conclusionOptions
                        : poolConclusionOptions"
                      :key="value"
                      :value="value"
                    >
                      {{ name }}
                    </option>
                  </b-select>
                </b-field>
              </td>
              <td class="has-text-centered has-text-vcentered">
                <b-checkbox
                  v-model="inputValues[i].repeat"
                  :disabled="result.isRepetition || !result.sampleId"
                />
              </td>
              <td class="has-text-centered">
                <b-tooltip
                  v-if="inputValues[i].observations === null"
                  label="Añadir"
                  type="is-light"
                >
                  <b-button type="is-text" @click="editObservation(i)">
                    <b-icon icon="plus" />
                  </b-button>
                </b-tooltip>
                <template v-else>
                  <b-tooltip label="Modificar" type="is-light">
                    <b-button type="is-text" @click="editObservation(i)">
                      <b-icon icon="pencil" />
                    </b-button>
                  </b-tooltip>
                </template>
              </td>
              <td class="has-text-centered valign-mid">
                <b-tooltip
                  label="Rechazar Muestra"
                  type="is-light"
                  position="is-left"
                >
                  <b-button
                    v-if="result.isRepetition === true"
                    type="is-text"
                    size="is-small"
                    @click="rejectSample(result)"
                  >
                    <b-icon
                      icon="flask-empty-remove-outline"
                      custom-size="mdi-24px"
                      type="is-danger"
                    />
                  </b-button>
                </b-tooltip>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else-if="!loading" class="container mt-3">
          <b-message> No hay resultados para validar. </b-message>
        </div>
      </div>
      <div v-if="resultsData.length > 0">
        <b-field grouped position="is-right">
          <div class="field-label is-normal">
            <label class="label"> Validado por </label>
          </div>

          <b-select
            v-if="analystsOptions.length > 0"
            v-model="analyst"
            placeholder="Validador"
          >
            <option
              v-for="option in analystsOptions"
              :key="option.id"
              :value="option.id"
            >
              {{ option.name }}
            </option>
          </b-select>

          <b-button
            type="is-primary"
            :loading="submitting"
            :disabled="analyst === null"
            @click="submitData"
          >
            Enviar
          </b-button>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
  import { routes } from "@/api";
  import { validate } from "@/utils";
  import AnnotationModal from "@/components/pcr-validation/AnnotationModal.vue";
  import RejectSampleModal from "@/components/samples/RejectSampleModal.vue";

  export default {
    name: "ResultValidation",
    components: {
      // eslint-disable-next-line vue/no-unused-components
      AnnotationModal,
    },
    data() {
      return {
        inputValues: [],
        analyst: null,
        resultsData: [],
        analystsOptions: [],
        conclusionOptions: {},
        poolConclusionOptions: {},
        tagTypes: {
          positive: "is-danger",
          negative: "is-light",
          inconclusive: "is-warning",
        },
        targetsInfo: [],
        submitting: false,
        loading: false,
        rejectionOptions: {},
      };
    },
    computed: {
      cutoffs() {
        return this.targetsInfo.reduce(
          (o, v) => ({ ...o, [v.name.replace(" ", "")]: v.cutoffs }),
          {}
        );
      },
      control() {
        return this.targetsInfo
          .filter((x) => x.type === "control")
          .map((x) => x.name)[0];
      },
      resultsFields() {
        const resultFields = [
          ...new Set(
            Object.values(this.resultsData)
              .map((x) => Object.keys(x.ctValues))
              .flat()
          ),
        ];

        if (resultFields.length === this.targetsInfo.length) {
          // if they're of the same length, should be no repetitions
          return this.targetsInfo.map((x) => x.name);
        } else {
          // if not, we need to repeat the control (multiple wells)
          const virusTargets = this.targetsInfo
            .filter((x) => x.type === "virus")
            .map((x) => x.name);

          return [
            ...virusTargets.map((x) => `${this.control.replace(" ", "")}_${x}`),
            ...virusTargets,
          ];
        }
      },
      targetTypes() {
        return this.resultsFields.length === this.targetsInfo.length
          ? [""]
          : [this.control, "Gene"];
      },
    },
    created() {
      this.loadData();
      this.loadRejectionOptions();
    },
    methods: {
      loadData() {
        this.loading = true;
        this.axios.get(routes.listResultsToValidate).then((response) => {
          this.resultsData = response.data.results;
          this.conclusionOptions = response.data.conclusionOptions;
          this.poolConclusionOptions = response.data.poolConclusionOptions;
          this.targetsInfo = response.data.targetsInfo;
          this.analystsOptions = response.data.analysts;
          this.initializeInputs();
          this.loading = false;
        });
      },
      initializeInputs() {
        this.inputValues = this.resultsData.map((x) => {
          return {
            analysisId: x.analysisId,
            sampleId: x.sampleId,
            poolId: x.poolId,
            validation: x.sampleId
              ? x.conclusion
              : this.poolValidation(x.conclusion),
            repeat: false,
            observations: null,
          };
        });
      },
      editObservation(idx) {
        this.$buefy.modal.open({
          parent: this,
          component: AnnotationModal,
          props: {
            title: "Observación",
            text: this.inputValues[idx].observations,
            withDelete: this.inputValues[idx].observations !== null,
          },
          events: {
            save: (observations, modal) => {
              this.inputValues[idx].observations = observations;
              modal.close();
            },
            delete: (modal) => {
              this.inputValues[idx].observations = null;
              modal.close();
            },
          },
          hasModalCard: true,
          trapFocus: true,
        });
      },
      formatFloat(value) {
        if (!isNaN(value) && value != null) {
          return value.toFixed(2);
        } else {
          return "--";
        }
      },
      formatPoolId(id) {
        return process.env.VITE_POOL_PREFIX + id.toString().padStart(3, "0");
      },
      formatPoolSamples(samples) {
        return `Muestras\n${samples.join("\n")}`;
      },
      poolValidation(validation) {
        return validation === "negative" ? validation : "repeat";
      },
      validate(target, value) {
        return validate(target, value, this.cutoffs);
      },
      getValueTextClass(target, value) {
        return this.validate(target, value)
          ? "has-text-success"
          : "has-text-danger";
      },
      submitData() {
        this.submitting = true;
        this.axios
          .post(routes.validateResults, {
            values: this.inputValues,
            analyst: {
              isNew: false,
              value: this.analyst,
            },
          })
          .then(() => {
            this.$buefy.toast.open({
              duration: 2000,
              message: "Cambios guardados correctamente",
              type: "is-success",
            });
            this.loadData();
          })
          .catch((e) => {
            this.$buefy.toast.open({
              duration: 3000,
              message: "Ha ocurrido un error",
              type: "is-danger",
            });
            this.$log.error(e);
          })
          .finally(() => {
            this.submitting = false;
          });
      },
      undoResultUpload() {
        this.$buefy.dialog.confirm({
          message:
            "Esta acción eliminará los resultados aún no validados (que se listan en esta pantalla).",
          type: "is-warning",
          cancelText: "Cancelar",
          onConfirm: () => {
            this.loading = true;
            this.axios.post(routes.undoResultsInput).then(() => {
              this.$buefy.toast.open({
                duration: 2000,
                message: "Cambios realizados correctamente",
                type: "is-success",
              });
              this.$router.push({ name: "DataInput" });
            });
          },
        });
      },
      formatDatetime(datetime) {
        return this.$date(datetime).format("DD-MM-YYYY HH:mm");
      },
      rejectSample(sample) {
        this.$buefy.modal.open({
          parent: this,
          component: RejectSampleModal,
          props: {
            sample: sample,
            rejectionOptions: this.rejectionOptions,
          },
          events: {
            saved: (modal) => {
              modal.close();
              this.resultsData = this.resultsData.filter(
                (x) => x.analysisId !== sample.analysisId
              );
              this.inputValues = this.inputValues.filter(
                (x) => x.analysisId !== sample.analysisId
              );
            },
          },
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
        });
      },
      loadRejectionOptions() {
        this.axios.get(routes.rejectionOptionsSSM).then((response) => {
          this.rejectionOptions = response.data.rejectOptions;
        });
      },
    },
  };
</script>

<style scoped lang="scss">
  @import "../../assets/scss/variables";

  .has-text-vcentered {
    vertical-align: middle;
  }

  .has-text-bottom {
    vertical-align: bottom;
  }

  .has-text-danger {
    color: $danger;
  }

  .has-text-success {
    color: $success;
  }

  .is-borderless {
    border: 0;
  }

  .empty-tbody {
    min-height: 180px;
  }

  .breakedline.b-tooltip:after {
    white-space: pre;
  }
</style>
