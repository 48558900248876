<template>
  <div class="modal-card has-radius" style="width: auto">
    <div class="modal-card-body">
      <div class="level">
        <div class="level-left">
          <span class="is-size-5 mr-4"> Instrucciones </span>
          <b-switch v-model="innerShowInstructions"></b-switch>
        </div>
      </div>
      <div v-if="innerShowInstructions" class="content has-text-grey">
        <ul>
          <li>
            El N° de Petición es opcional. Se generará un código interno del
            laboratorio para cada muestra (y éste será el indicado en los
            reportes entregados posteriormente).
          </li>
          <li>
            El RUN debe ser ingresado sin puntos, pero
            <strong>con guión y dígito verificador</strong>.
          </li>
          <li>
            Los nombres y apellidos pueden estar en mayúsculas o minúsculas,
            serán transformados a mayúsculas en el ingreso.
          </li>
          <li>
            Se debe indicar al menos un campo de contacto (teléfono, dirección o
            e-mail).
          </li>
          <li>
            En caso de ingresar múltiples números telefónicos, separalos con
            comas (sin espacios).
          </li>
        </ul>
      </div>
      <validation-observer ref="observer">
        <span class="is-size-5 mb-3 has-text-grey"> Datos de la Muestra </span>
        <b-field grouped class="mb-0">
          <b-select-with-validation
            v-model="data.examTypeId"
            rules="required"
            name="examType"
            label="Tipo de Examen"
            class="field"
          >
            <option v-for="exam in examTypes" :key="exam.id" :value="exam.id">
              {{ exam.shortName }}
            </option>
          </b-select-with-validation>
          <b-input-with-validation
            v-model="data.sampleType"
            :rules="{ required: true, regex: /^[A-Za-z0-9 .-\\(\\)]+$/ }"
            name="sampleType"
            label="Tipo de Muestra"
            maxlength="64"
            class="field is-expanded"
          />
          <validation-provider
            v-slot="{ errors, valid }"
            name="samplingDatetime"
            :rules="{
              required: true,
            }"
          >
            <b-field
              label="Toma de muestra"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-datetimepicker
                v-model="data.samplingDatetime"
                placeholder="DD-MM-AAAA HH:MM"
                icon="calendar-today"
                :datepicker="datepickerOptions"
                :timepicker="{ hourFormat: '24' }"
                :datetime-parser="parseDatetime"
                :max-datetime="new Date()"
                append-to-body
              />
            </b-field>
          </validation-provider>
        </b-field>
        <b-field grouped class="mb-0">
          <b-select-with-validation
            v-model="data.institutionId"
            rules="required"
            label="Institución"
            class="field"
          >
            <option
              v-for="institution in institutions"
              :key="institution.id"
              :value="institution.id"
            >
              [{{ institution.prefix }}] {{ institution.name }}
            </option>
          </b-select-with-validation>
          <b-input-with-validation
            v-model="data.origin"
            :rules="{ required: false, regex: /^[A-Za-z0-9 .-\\(\\)]+$/ }"
            name="origin"
            label="Procedencia"
            maxlength="64"
            class="field is-expanded"
          />
          <b-input-with-validation
            v-model="data.externalId"
            rules="integer"
            name="externalId"
            label="N° de Petición"
            class="field field-external-id"
          />
        </b-field>
        <b-field grouped>
          <b-input-with-validation
            v-model="data.requestedBy"
            :rules="{ required: true, regex: /^[A-Za-z .]+$/ }"
            name="requestedBy"
            label="Médico solicitante"
            maxlength="128"
            class="field is-expanded"
          />

          <b-select-with-validation
            v-model="data.sampleCategoryId"
            label="Categoría"
            class="field"
          >
            <option
              v-for="category in sampleCategories"
              :key="category.id"
              :value="category.id"
            >
              {{ category.name }}
            </option>
          </b-select-with-validation>

          <b-select-with-validation
            v-model="data.priority"
            rules="required"
            label="Prioridad"
            class="field"
          >
            <option value="normal">Normal</option>
            <option value="urgent">Urgente</option>
          </b-select-with-validation>

          <b-select-with-validation
            v-if="data.priority === 'urgent'"
            v-model="data.urgencyCriterionId"
            rules="required"
            label="Criterio de Urgencia"
            class="field"
          >
            <option
              v-for="urgencyCriterium in urgencyCriteria"
              :key="urgencyCriterium.id"
              :value="urgencyCriterium.id"
            >
              {{ urgencyCriterium.name }}
            </option>
          </b-select-with-validation>
        </b-field>

        <span class="is-size-5 mb-3 has-text-grey"> Datos del Paciente </span>
        <b-field grouped class="mb-0">
          <b-input-with-validation
            v-model="data.idString"
            name="idString"
            label="RUN / Pasaporte"
            maxlength="10"
            class="field field-id-string"
            placeholder="XXXXXXXX[-X]"
            rules="required"
          >
          </b-input-with-validation>

          <p v-if="!showPatientFields" class="control">
            <label class="label">&#8203;</label>
            <b-button
              class="button is-primary"
              :loading="loadingPatientdata"
              @click="searchPatientData"
            >
              Buscar Paciente
            </b-button>
          </p>

          <b-input-with-validation
            v-show="showPatientFields"
            v-model="data.names"
            rules="required|alpha_spaces"
            name="names"
            label="Nombre(s)"
            maxlength="64"
            class="field is-expanded"
          />

          <b-input-with-validation
            v-show="showPatientFields"
            v-model="data.firstLastname"
            rules="required|alpha_spaces"
            name="firstLastname"
            label="Apellido Paterno"
            maxlength="32"
            class="field field-lastname"
          />

          <b-input-with-validation
            v-show="showPatientFields"
            v-model="data.secondLastname"
            rules="alpha_spaces"
            name="secondLastname"
            label="Apellido Materno"
            maxlength="32"
            class="field field-lastname"
          />

          <b-select-with-validation
            v-show="showPatientFields"
            v-model="data.sex"
            rules="required|oneOf:F,M"
            label="Sexo"
            class="field field-sex"
          >
            <option value="M">Hombre</option>
            <option value="F">Mujer</option>
          </b-select-with-validation>
        </b-field>

        <b-field v-show="showPatientFields" grouped>
          <validation-provider
            v-slot="{ errors, valid }"
            name="birthDate"
            class="field"
            :rules="{ required: false }"
          >
            <b-field
              label="Fecha de Nacimiento"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-datepicker
                v-model="data.dateOfBirth"
                placeholder="DD-MM-AAAA"
                icon="calendar-today"
                :month-names="datepickerOptions.monthNames"
                :day-names="datepickerOptions.dayNames"
                :first-day-of-week="1"
                :date-parser="parseDate"
                :max-date="new Date()"
                position="is-top-right"
                append-to-body
              />
            </b-field>
          </validation-provider>

          <b-input-with-validation
            v-model="data.phone"
            vid="phone"
            :rules="{
              contactData: emptyContactData,
              regex: /^\d+(,\d+)*$/,
            }"
            name="phone"
            label="Teléfono(s)"
            maxlength="32"
            class="field is-expanded"
          />

          <b-input-with-validation
            v-model="data.address"
            vid="address"
            :rules="{
              contactData: emptyContactData,
            }"
            name="address"
            label="Dirección"
            maxlength="128"
            class="field is-expanded"
          />

          <b-input-with-validation
            v-model="data.email"
            vid="email"
            :rules="{
              email: true,
              contactData: emptyContactData,
            }"
            name="email"
            label="Correo Electrónico"
            maxlength="64"
            class="field is-expanded"
          />
        </b-field>

        <div class="buttons mt-5">
          <b-button icon-left="content-save" type="is-primary" @click="submit">
            <span>Guardar</span>
          </b-button>
          <b-button @click="$emit('cancel', $parent)">
            <span>Cancelar</span>
          </b-button>
        </div>
      </validation-observer>
    </div>
  </div>
</template>

<script>
  import { datepickerOptions } from "@/utils";
  import { ValidationObserver, ValidationProvider } from "vee-validate";
  import BInputWithValidation from "@/components/inputs/BInputWithValidation.vue";
  import BSelectWithValidation from "@/components/inputs/BSelectWithValidation.vue";
  import { routes } from "@/api";

  export default {
    name: "SampleInputForm",
    components: {
      BSelectWithValidation,
      BInputWithValidation,
      ValidationObserver,
      ValidationProvider,
    },
    props: {
      sample: {
        type: Object,
        default: () => {},
      },
      enteredSamples: {
        type: Array,
        default: () => [],
      },
      examTypes: {
        type: Array,
        required: true,
      },
      sampleCategories: {
        type: Array,
        required: true,
      },
      institutions: {
        type: Array,
        required: true,
      },
      showInstructions: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        data: {},
        urgencyCriteria: [],
        innerShowInstructions: this.$props.showInstructions,
        datepickerOptions: datepickerOptions,
        showPatientFields: false,
        loadingPatientdata: false,
      };
    },
    computed: {
      emptyContactData() {
        return [this.data.phone, this.data.address, this.data.email].every(
          (x) => x === "" || x === null
        );
      },
    },
    watch: {
      innerShowInstructions(value) {
        this.$emit("update:show-instructions", value);
      },
    },
    created() {
      if (Object.keys(this.$props.sample).length > 0) {
        this.data = this.$props.sample;
        this.showPatientFields = true;
      } else {
        this.data = {
          idString: "",
          dateOfBirth: null,
          sex: null,
          names: "",
          firstLastname: "",
          secondLastname: "",
          samplingDatetime: null,
          sampleType: "",
          examTypeId: null,
          sampleCategoryId: 1,
          institutionId: null,
          origin: "",
          externalId: null,
          phone: "",
          address: "",
          email: "",
          valid: false,
          priority: "normal",
          urgencyCriterionId: null,
          requestedBy: "",
        };
      }

      this.loadUrgencyCriteria();
    },
    mounted() {
      if (Object.keys(this.$props.sample).length > 0) {
        this.$refs.observer.validate();
      }
    },
    methods: {
      loadUrgencyCriteria() {
        this.axios
          .get(routes.getUrgencyCriteria)
          .then((response) => {
            if (response.data.status === "OK") {
              this.urgencyCriteria = response.data.results;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
      submit() {
        this.$refs.observer.validate().then((valid) => {
          if (valid) {
            this.data.valid = true;
            this.$emit("save", this.data, this.$parent);
          }
        });
      },
      searchPatientData() {
        // first search in the entered samples
        const matchingPatientSample = this.enteredSamples.find(
          (x) => x.idString === this.data.idString
        );

        if (matchingPatientSample) {
          const patientData = Object.fromEntries(
            [
              "names",
              "firstLastname",
              "secondLastname",
              "phone",
              "address",
              "email",
              "sex",
              "dateOfBirth",
            ]
              .filter((key) => key in matchingPatientSample)
              .map((key) => [key, matchingPatientSample[key]])
          );

          this.data = {
            ...this.data,
            ...patientData,
          };
          this.showPatientFields = true;
        } else {
          this.searchPatientDataRemote();
        }
      },
      searchPatientDataRemote() {
        this.loadingPatientdata = true;
        this.axios
          .get(routes.patientInfo.replace(":id", this.data.idString))
          .then((response) => {
            if (response.data.status === "OK") {
              const patient = response.data.result;
              this.data.names = patient.names;
              this.data.firstLastname = patient.firstLastname;
              this.data.secondLastname = patient.secondLastname;
              this.data.phone = patient.phone;
              this.data.address = patient.address;
              this.data.email = patient.email;
              this.data.sex = patient.sex[0];

              const dob = this.$date(patient.dateOfBirth, "YYYY-MM-DD");
              if (dob.isValid()) {
                this.data.dateOfBirth = dob.toDate();
              }
            }
          })
          .finally(() => {
            this.loadingPatientdata = false;
            this.showPatientFields = true;
          });
      },
      parseDate(date) {
        return this.$date(date, "DD-MM-YYYY").toDate();
      },
      parseDatetime(datetime) {
        return this.$date(datetime, "DD-MM-YYYY HH:mm").toDate();
      },
    },
  };
</script>

<style scoped>
  .field-id-string {
    max-width: 150px;
  }

  .field-external-id {
    max-width: 120px;
  }

  .field-lastname {
    max-width: 180px;
  }

  .field-sex {
    max-width: 120px;
  }

  .has-radius {
    border-radius: 6px;
  }
</style>
