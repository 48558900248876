<template>
  <div class="container mt-5">
    <h1 class="title">Recepción de Muestras (SSM)</h1>
    <p class="has-text-grey mb-3">
      Módulo para recepcionar muestras a desde la plataforma "Toma de Muestras"
      del Servicio de Salud de Magallanes. Las muestras recepcionadas serán
      agrupadas en un lote y quedarán en un estado de pre-recepción.
    </p>

    <div
      v-if="
        (searchText === null && selectedGroup === null) || searchText !== null
      "
      class="columns mt-5"
    >
      <div class="column is-half is-offset-one-quarter">
        <form @submit.prevent>
          <b-field>
            <b-input
              v-model="searchTextTmp"
              placeholder="N° de Muestra, RUN, Apellido..."
              :disabled="searchText !== null"
              expanded
            >
            </b-input>
            <p v-if="searchText === null" class="control">
              <b-button
                class="button is-primary"
                native-type="submit"
                icon-left="magnify"
                @click="searchText = searchTextTmp"
              >
                Filtrar
              </b-button>
            </p>
            <p v-else class="control">
              <b-button
                class="button is-danger"
                native-type="submit"
                icon-left="close"
                @click="cancelSearch"
              >
                Cancelar
              </b-button>
            </p>
          </b-field>
        </form>
      </div>
    </div>

    <template v-if="selectedGroup === null">
      <b-field grouped position="is-right">
        <b-button
          type="is-primary"
          icon-left="reload"
          :disabled="loading"
          outlined
          @click="loadSamples"
        >
          Recargar Muestras
        </b-button>
      </b-field>

      <div>
        <b-table
          v-if="Object.keys(samples).length > 0 || loading"
          :data="sampleSummary"
          :loading="loading"
        >
          <b-table-column
            v-for="column in summaryTableColumns"
            :key="column.field"
            v-slot="props"
            :label="column.label"
            :field="column.field"
            :centered="column.centered"
            cell-class="valign-mid"
          >
            {{ props.row[column.field] }}
          </b-table-column>
          <b-table-column v-slot="props" cell-class="valign-mid" centered>
            <b-tooltip label="Contiene muestras priorizadas" type="is-light">
              <b-icon
                v-if="props.row.hasPriority"
                icon="alert-rhombus"
                type="is-danger"
              />
            </b-tooltip>
          </b-table-column>
          <b-table-column v-slot="props" centered>
            <b-button type="is-primary" @click="selectedGroup = props.index">
              Detalles
            </b-button>
          </b-table-column>
          <template slot="empty"><div class="table-empty" /></template>
        </b-table>
      </div>

      <template v-if="invalidSamples.length > 0">
        <section>
          <b-collapse
            class="card is-shadowless has-border-light mt-5"
            animation="slide"
            :open="false"
          >
            <div
              slot="trigger"
              slot-scope="props"
              class="card-header is-shadowless has-background-light"
              role="button"
            >
              <p class="card-header-title">Muestras con problemas</p>
              <a class="card-header-icon">
                <b-icon :icon="props.open ? 'menu-up' : 'menu-down'" />
              </a>
            </div>
            <div class="card-content">
              <div class="content">
                <section>
                  <p class="has-text-grey mb-3">
                    Las siguientes muestras están anuladas o no presentan
                    rut/pasaporte para el paciente asociado y no están
                    disponibles para su recepción, por favor entrar en contacto
                    con el administrador para el rechazo o recepción de las
                    muestras.
                  </p>
                  <b-table :data="invalidSamplesFormatted" :loading="loading">
                    <b-table-column
                      v-for="column in invalidTableColumns"
                      v-slot="props"
                      :key="column.field"
                      :field="column.field"
                      :label="column.label"
                      :centered="column.centered"
                      cell-class="valign-mid"
                    >
                      {{ props.row[column.field] }}
                    </b-table-column>
                  </b-table>
                </section>
              </div>
            </div>
          </b-collapse>
        </section>
      </template>
    </template>

    <SampleReceptionSSM
      v-else
      :institution="institutionsById[samplesFlat[selectedGroup].institutionId]"
      :samples="samplesFlat[selectedGroup].samples"
      :laboratory="samplesFlat[selectedGroup].laboratory"
      @unselect="selectedGroup = null"
      @preceived-samples="removeSamples(selectedGroup, $event)"
    />
  </div>
</template>

<script>
  import { routes } from "@/api";
  import SampleReceptionSSM from "@/components/samples/SampleReceptionSSM.vue";

  export default {
    name: "ListReceivedSamplesSSM",
    components: { SampleReceptionSSM },
    data() {
      return {
        samples: {},
        institutions: [],
        invalidSamples: [],
        selectedGroup: null,
        searchText: null,
        searchTextTmp: null,
        loading: false,
        summaryTableColumns: [
          { field: "institution", label: "Institución de Origen" },
          { field: "laboratory", label: "Laboratorio Derivador" },
          { field: "samples", label: "Muestras", centered: true },
          {
            field: "dispatchRange",
            label: "Despacho (Desde - Hasta)",
            centered: true,
          },
        ],
        invalidTableColumns: [
          {
            field: "institution",
            label: "Institución de Origen",
            centered: false,
          },
          {
            field: "idString",
            label: "Nº Documento",
            centered: true,
          },
          { field: "id", label: "Número de Muestra", centered: true },
          {
            field: "fullName",
            label: "Nombre Completo",
            centered: true,
          },
          {
            field: "samplingDatetime",
            label: "Toma de Muestra",
            centered: true,
          },
          {
            field: "state",
            label: "Estado",
            centered: true,
          },
          {
            field: "priority",
            label: "Prioridad",
            centered: true,
          },
        ],
        priorityOptions: {
          normal: { label: "Normal", class: "" },
          high: { label: "Media", class: "has-text-warning bold" },
          urgent: { label: "Urgente", class: "has-text-danger bold" },
        },
      };
    },
    computed: {
      institutionsById() {
        return this.institutions.reduce((o, v) => ({ ...o, [v.id]: v }));
      },
      samplesFlat() {
        return Object.entries(this.samples)
          .reduce((list, [lab, samples]) => {
            lab = lab === "null" ? "-" : lab;
            return list.concat(
              Object.entries(samples).map(([k, v]) => ({
                laboratory: lab,
                institutionId: k,
                samples: v.filter((x) => this.searchInSample(x)),
              }))
            );
          }, [])
          .filter((x) => x.samples.length > 0);
      },
      invalidSamplesFormatted() {
        return this.invalidSamples.map((x) => ({
          idString: x.idString === null ? "-" : x.idString,
          institution: this.institutionsById[x.institutionId].name,
          fullName: `${x.names} ${x.firstLastname} ${x.secondLastname}`,
          id: x.externalId,
          samplingDatetime: `${this.$date(x.samplingDatetime).format(
            "DD/MM HH:mm"
          )}`,
          state: x.state,
          priority: this.priorityOptions[x.priority].label,
        }));
      },
      sampleSummary() {
        return this.samplesFlat.map((x) => ({
          laboratory: x.laboratory,
          institution: this.institutionsById[x.institutionId].name,
          institutionId: x.institutionId,
          samples: x.samples.length,
          dispatchRange: `${this.$date(x.samples[0].dispatchDatetime).format(
            "DD/MM HH:mm"
          )} - ${this.$date(
            x.samples[x.samples.length - 1].dispatchDatetime
          ).format("DD/MM HH:mm")}`,
          hasPriority: x.samples.some((x) => x.priority !== "normal"),
        }));
      },
    },
    created() {
      this.loadSamples();
    },
    methods: {
      loadSamples() {
        this.cancelSearch();
        this.loading = true;
        this.axios
          .get(routes.getReceivedSamplesSSM)
          .then((response) => {
            if (response.data.status !== "OK") {
              return Promise.reject(response.data.message);
            }
            this.samples = response.data.samples;
            this.institutions = response.data.institutions;
            this.invalidSamples = response.data.invalid;
          })
          .catch((e) => {
            this.$buefy.toast.open({
              duration: 5000,
              message: "Ha ocurrido un error al cargar las muestras recibidas",
              type: "is-danger",
            });
            this.$log.error(e);
          })
          .finally(() => {
            this.loading = false;
          });
      },
      removeSamples(index, samples) {
        const laboratory =
          this.samplesFlat[index].laboratory !== "-"
            ? this.samplesFlat[index].laboratory
            : "null";
        const institutionId = this.samplesFlat[index].institutionId;

        const remainingSamples = this.samples[laboratory][institutionId].filter(
          (x) => !samples.includes(x.externalId)
        );

        if (remainingSamples.length === 0) {
          this.selectedGroup = null;
        }

        this.samples[laboratory][institutionId] = remainingSamples;
      },
      searchInSample(sample) {
        if (this.searchText === null) {
          return true;
        } else {
          const fullName = `${sample.names} ${sample.firstLastname} ${sample.secondLastname}`;
          const searchText = this.searchText.trim().toUpperCase();
          return (
            fullName.includes(searchText) ||
            sample.idString.startsWith(searchText) ||
            String(sample.externalId) === searchText
          );
        }
      },
      cancelSearch() {
        this.searchText = this.searchTextTmp = null;
      },
    },
  };
</script>

<style>
  .valign-mid {
    vertical-align: middle !important;
  }
  .has-border-light {
    border: hsl(0, 0%, 96%) 1px solid;
  }
</style>

<style scoped></style>
