<template>
  <div class="container mt-5">
    <h1 class="title">Gestión de Muestras en Lote</h1>
    <p class="subtitle is-5">Lote {{ batchId }} ({{ institution }})</p>
    <p class="has-text-grey">
      Seleccione una o más muestras de la lista y presione el botón que
      corresponda a la acción que desea realizar.
    </p>

    <b-loading
      :active="loading"
      :is-full-page="false"
      class="min-height-400px"
    />
    <div class="mt-4">
      <section>
        <b-collapse
          v-for="status in availableStatuses"
          :key="status.key"
          class="card is-shadowless has-border-light"
          animation="slide"
          :open="selectedStatus === status.key && !loading"
          @open="
            selectedStatus = status.key;
            selectedSamples = [];
          "
        >
          <div
            slot="trigger"
            slot-scope="props"
            class="card-header is-shadowless has-background-light"
            role="button"
          >
            <p class="card-header-title">
              {{ status.label }} ({{ samplesByStatus[status.label].length }})
            </p>
            <a class="card-header-icon">
              <b-icon :icon="props.open ? 'menu-up' : 'menu-down'" />
            </a>
          </div>
          <div class="card-content">
            <div class="content">
              <section>
                <b-table
                  :data="samplesByStatus[status.label]"
                  :checked-rows.sync="selectedSamples"
                  checkable
                  narrowed
                >
                  <template v-for="column in columns">
                    <b-table-column
                      v-slot="props"
                      :key="column.field"
                      v-bind="column"
                      :sortable="column.sortable"
                      cell-class="valign-mid"
                    >
                      <template v-if="column.field.includes('Datetime')">
                        {{ formatDateTime(props.row[column.field]) }}
                      </template>
                      <template v-else>
                        {{ props.row[column.field] }}
                      </template>
                    </b-table-column>
                  </template>
                  <b-table-column
                    v-slot="props"
                    field="details"
                    cell-class="valign-mid"
                    centered
                  >
                    <b-tooltip
                      type="is-warning"
                      size="is-large"
                      multilined
                      position="is-bottom"
                      :animated="false"
                      append-to-body
                    >
                      <b-icon
                        v-if="
                          samplingInvalidInterval(
                            props.row.sampleId,
                            props.row.samplingDatetime
                          )
                        "
                        icon="information"
                        type="is-warning"
                      />
                      <template #content>
                        <b>Menos de 72hr entre toma de muestras</b><br />
                        Última toma muestra:
                        {{ getLastSamplingDate(props.row.sampleId) }}
                        <br />
                        <b>Resultado</b>:
                        <span
                          :class="getLastConclusion(props.row.sampleId)"
                          class="underline"
                        >
                          {{ getLastConclusion(props.row.sampleId) }}
                        </span>
                        <br />
                      </template>
                    </b-tooltip>
                  </b-table-column>
                  <template v-if="status.key === 'prereceived'">
                    <b-table-column
                      v-slot="props"
                      label="Rechazar"
                      centered
                      cell-class="valign-mid"
                    >
                      <b-tooltip label="Rechazar Muestra" type="is-light">
                        <b-button
                          type="is-text"
                          size="is-small"
                          @click="rejectSample(props.row.sampleId)"
                        >
                          <b-icon
                            icon="flask-empty-remove-outline"
                            custom-size="mdi-18px"
                            type="is-danger"
                          />
                        </b-button>
                      </b-tooltip>
                    </b-table-column>
                  </template>
                </b-table>
              </section>
              <section class="mt-4 mb-4">
                <template v-if="status.key === 'prereceived'">
                  <div class="buttons is-centered">
                    <b-button
                      type="is-primary"
                      icon-left="check-bold"
                      :loading="saving"
                      :disabled="selectedSamples.length === 0"
                      @click="receiveSamples"
                    >
                      Enviar a Extracción
                    </b-button>
                    <b-button
                      type="is-danger"
                      icon-left="delete"
                      :loading="deleting"
                      :disabled="selectedSamples.length === 0"
                      @click="deleteSamplesDialog"
                    >
                      Eliminar
                    </b-button>
                  </div>
                </template>
                <template v-else-if="status.key === 'in_extraction'">
                  <div class="buttons is-centered">
                    <b-button
                      type="is-primary"
                      icon-left="check-bold"
                      :loading="saving"
                      :disabled="selectedSamples.length === 0"
                      @click="updateSamplesStatus('in_pcr')"
                    >
                      Enviar a PCR
                    </b-button>
                  </div>
                </template>
              </section>
            </div>
          </div>
        </b-collapse>
      </section>
    </div>
  </div>
</template>

<script>
  import { routes } from "@/api";
  import RejectSampleModal from "@/components/samples/RejectSampleModal.vue";
  import ReceiveSamplesModal from "@/components/samples/ReceiveSamplesModal.vue";
  import { formatDateTime } from "@/utils";

  export default {
    name: "BatchManagement",
    data() {
      return {
        samples: [],
        conclusions: [],
        conclusionOptions: {},
        selectedSamples: [],
        institution: "",
        rejectionOptions: {},
        columns: [
          {
            field: "sampleId",
            label: "N° Muestra",
            centered: true,
            sortable: true,
          },
          {
            field: "externalId",
            label: "N° Petición (Ex.)",
            centered: true,
            sortable: true,
          },
          {
            field: "idString",
            label: "RUN / Pas.",
            centered: true,
            sortable: true,
          },
          { field: "name", label: "Nombre", centered: true, sortable: true },
          {
            field: "samplingDatetime",
            label: "Toma de Muestra",
            centered: true,
            sortable: true,
          },
        ],
        manageableStatuses: {
          prereceived: "Pre Recepcionado",
          in_extraction: "En Extracción",
        },
        selectedStatus: null,
        statusMessages: [],
        batchId: null,
        loadingSamples: false,
        loadingOptions: false,
        saving: false,
        deleting: false,
      };
    },
    computed: {
      samplesFormatted() {
        return this.samples.map((x) => ({
          ...x,
          name: `${x.firstLastname} ${
            x.secondLastname ? x.secondLastname : ""
          } ${x.names}`,
          samplingDatetime: this.$date(x.samplingDatetime),
        }));
      },
      samplesByStatus() {
        return this.samplesFormatted.reduce((o, v) => {
          if (!o[v.status]) {
            o[v.status] = [];
          }
          o[v.status].push(v);

          return o;
        }, {});
      },
      availableStatuses() {
        const statusKeyByLabel = Object.entries(this.manageableStatuses).reduce(
          (o, [k, v]) => ({ ...o, [v]: k }),
          {}
        );

        return Object.keys(this.samplesByStatus)
          .filter((x) => Object.values(this.manageableStatuses).includes(x))
          .map((x) => ({ key: statusKeyByLabel[x], label: x }));
      },
      loading() {
        return (
          this.loadingSamples ||
          (this.loadingOptions &&
            !this.loadingSamples &&
            this.availableStatuses.map((x) => x.key).includes("prereceived"))
        );
      },
    },
    watch: {
      availableStatuses(val) {
        if (val.length > 0) {
          this.selectedStatus = val[0].key;
        } else if (val.length === 0) {
          this.$router.push({ name: "SampleBatches" });
        }
      },
    },
    created() {
      this.batchId = Number(this.$route.params.id);
      this.loadSamples();
      this.loadRejectionOptions();
    },
    methods: {
      loadSamples() {
        this.loadingSamples = true;
        this.selectedStatus = null;
        this.selectedSamples = [];
        this.axios
          .get(routes.batchSamples.replace(":id", String(this.batchId)), {
            params: { simple: true },
          })
          .then((response) => {
            if (response.data.status === "OK") {
              this.samples = response.data.results;
              this.conclusions = response.data.samples_conclusion;
              this.conclusionOptions = response.data.conclusionOptions;
              this.institution = response.data.institution;
              this.loadingSamples = false;
            } else {
              this.$router.push({ name: "SampleBatches" });
            }
          });
      },
      loadRejectionOptions() {
        this.loadingOptions = true;
        this.axios.get(routes.rejectionOptionsSSM).then((response) => {
          this.rejectionOptions = response.data.rejectOptions;
          this.loadingOptions = false;
        });
      },
      updateSamplesStatus(status, samples = null, fullSave = true) {
        if (samples === null) {
          samples = this.selectedSamples;
        }
        const selectedIds = samples.map((x) => x.sampleId);

        this.saving = true;
        this.statusMessages = [];

        this.axios
          .post(routes.updateSamplesStatus, {
            samples: selectedIds,
            status: status,
            batchId: Number(this.batchId),
          })
          .then(() => {
            this.successUpdate(fullSave);
          })
          .catch((e) => {
            this.$buefy.toast.open({
              duration: 3000,
              message: "Ha ocurrido un error",
              type: "is-danger",
            });
            this.$log.error(e);
          })
          .finally(() => {
            this.saving = false;
          });
      },
      rejectSample(sampleId) {
        const sample = this.samples.find((x) => x.sampleId === sampleId);

        this.$buefy.modal.open({
          parent: this,
          component: RejectSampleModal,
          props: {
            sample: sample,
            rejectionOptions: this.rejectionOptions,
          },
          events: {
            saved: (modal) => {
              modal.close();
              this.loadSamples();
            },
          },
          hasModalCard: true,
          trapFocus: true,
          canCancel: false,
        });
      },
      receiveSamples() {
        const fromIntegration = this.selectedSamples.some(
          (x) => x.fromIntegration
        );

        if (fromIntegration) {
          this.$buefy.modal.open({
            parent: this,
            component: ReceiveSamplesModal,
            props: {
              samples: this.selectedSamples,
              batchId: this.batchId,
            },
            events: {
              saved: (modal, fullSave = true) => {
                if (fullSave) {
                  modal.close();
                }
                this.successUpdate(fullSave);
              },
            },
            hasModalCard: true,
            trapFocus: true,
            canCancel: false,
          });
        } else {
          this.updateSamplesStatus("in_extraction");
        }
      },
      deleteSamplesDialog() {
        this.$buefy.dialog.confirm({
          title: "Eliminación de Muestras",
          message:
            "Estas muestras serán eliminadas. Esta operación es <strong>IRREVERSIBLE</strong>. " +
            "No se generará ningún cambio en la plataforma de Toma de Muestras.<br><br><strong>Muestras</strong>: " +
            this.selectedSamples.map((x) => String(x.sampleId)).join(", "),
          confirmText: "Eliminar Muestras",
          type: "is-danger",
          onConfirm: () => this.deleteSamples(),
        });
      },
      deleteSamples() {
        this.deleting = true;
        this.axios
          .post(routes.deleteSamples, {
            samples: this.selectedSamples.map((x) => x.sampleId),
          })
          .then((response) => {
            if (response.data.emptyBatches.includes(this.batchId)) {
              this.$router.push({ name: "SampleBatches" });
            } else {
              this.loadSamples();
            }
          })
          .catch((e) => {
            this.$buefy.toast.open({
              duration: 2000,
              message: "Ha ocurrido un error",
              type: "is-danger",
            });
            this.$log.error(e);
          })
          .finally(() => {
            this.deleting = false;
          });
      },
      successUpdate(fullSave) {
        if (fullSave) {
          this.$buefy.toast.open({
            duration: 2000,
            message: "Cambios guardados correctamente",
            type: "is-success",
          });
        } else {
          this.$buefy.toast.open({
            duration: 2000,
            message: "Cambios guardados parcialmente",
            type: "is-warning",
          });
        }
        this.loadSamples();
      },
      formatDateTime(datetime) {
        return formatDateTime(datetime);
      },
      samplingInvalidInterval(sampleId, CurrentSamplingDate) {
        // Check if 72hr has been pass between sampling
        if (this.conclusions[sampleId]) {
          const lastSamplingDate = this.$date(
            this.conclusions[sampleId].samplingDatetime
          );
          const currentSamplingDate = this.$date(CurrentSamplingDate);
          const _MS_PER_HOUR = 60 * 60 * 1000;
          const dateDiff = Math.round(
            (currentSamplingDate - lastSamplingDate) / _MS_PER_HOUR
          );
          return dateDiff <= 72;
        } else {
          return false;
        }
      },
      getLastConclusion(sampleId) {
        return this.conclusions[sampleId]
          ? this.conclusionOptions[this.conclusions[sampleId].conclusion]
          : null;
      },
      getLastSamplingDate(sampleId) {
        return this.conclusions[sampleId]
          ? formatDateTime(this.conclusions[sampleId].samplingDatetime)
          : null;
      },
    },
  };
</script>

<style scoped>
  .has-border-light {
    border: hsl(0, 0%, 96%) 1px solid;
  }
  .min-height-400px {
    min-height: 400px;
  }
  .valign-mid {
    vertical-align: middle !important;
  }
  span.positive {
    color: hsl(348, 100%, 61%);
  }
  span.inconclusive {
    color: hsl(55, 80%, 29%);
  }
  span.negative {
    color: hsl(0, 0%, 21%);
  }
  span.underline {
    text-decoration: underline;
  }
</style>
