<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <form @submit.prevent>
      <div class="modal-card" style="width: auto">
        <b-loading :active="loading" :is-full-page="false" />

        <header class="modal-card-head">
          <p class="modal-card-title">Modificar registro Nº{{ cellData.id }}</p>
        </header>
        <section class="modal-card-body">
          <div class="mb-3">
            <b-field grouped>
              <b-field expanded>
                <b-input-with-validation
                  v-model="formData.experiment_id"
                  rules="required|alpha_num|unique"
                  name="experimentId"
                  label="Número experimento"
                  class="field"
                  :debounce="500"
                />
              </b-field>

              <b-field expanded>
                <b-input-with-validation
                  v-model="formData.cell_id"
                  :rules="{ required: true, alpha_num: true }"
                  name="cellId"
                  label="Código celda"
                  class="field"
                />
              </b-field>

              <b-field expanded>
                <b-input-with-validation
                  v-model="formData.ligation_batch"
                  :rules="{ required: true, regex: /^[\w.]+$/ }"
                  name="ligationBatch"
                  label="N°Lote Kit Ligación"
                  class="field"
                />
              </b-field>

              <b-field expanded>
                <b-input-with-validation
                  v-model="formData.barcode_batch"
                  :rules="{ required: true, regex: /^[\w.]+$/ }"
                  name="barcodeBatch"
                  label="N°Lote Kit Barcode"
                  class="field"
                />
              </b-field>
            </b-field>

            <b-field grouped>
              <b-field>
                <b-input-with-validation
                  v-model="formData.pores"
                  :rules="{
                    required: true,
                    integer: true,
                  }"
                  name="pores"
                  label="Número de poros activos"
                  class="field"
                />
              </b-field>
              <validation-provider
                v-slot="{ errors, valid }"
                name="startDatetime"
                :rules="{
                  required: true,
                }"
              >
                <b-field
                  label="Fecha y hora de inicio"
                  name="startDatetime"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="errors"
                >
                  <b-datetimepicker
                    v-model="formData.start_datetime"
                    placeholder="DD-MM-AAAA HH:SS"
                    icon="calendar-today"
                    :datepicker="datepickerOptions"
                    :timepicker="{ hourFormat: '24' }"
                    append-to-body
                    class="field"
                  />
                </b-field>
              </validation-provider>

              <validation-provider
                v-slot="{ errors, valid }"
                name="finishDatetime"
                :rules="{
                  required: true,
                }"
              >
                <b-field
                  label="Fecha y hora de término"
                  name="finishDatetime"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="errors"
                >
                  <b-datetimepicker
                    v-model="formData.finish_datetime"
                    placeholder="DD-MM-AAAA HH:SS"
                    icon="calendar-today"
                    :datepicker="datepickerOptions"
                    :timepicker="{ hourFormat: '24' }"
                    append-to-body
                    class="field"
                  />
                </b-field>
              </validation-provider>
            </b-field>

            <b-field grouped group-multiline>
              <b-select-with-validation
                v-model="selectedEquipment"
                :rules="{
                  required: true,
                  oneOf: equipmentTypes,
                }"
                name="equipmentType"
                label="Equipo"
                class="field"
              >
                <option
                  v-for="equipment in equipmentTypes"
                  :key="equipment"
                  :value="equipment"
                >
                  {{ equipment }}
                </option>
              </b-select-with-validation>

              <b-select-with-validation
                v-model="formData.sequencing_equipment_id"
                :rules="{
                  required: true,
                  oneOf: getValues(equipmentOptions),
                }"
                name="equipmentId"
                label="Código Equipo"
                class="field"
              >
                <option
                  v-for="equipment in equipmentOptions"
                  :key="equipment.value"
                  :value="equipment.value"
                >
                  {{ equipment.option }}
                </option>
              </b-select-with-validation>

              <b-select-with-validation
                v-model="formData.ligation_kit_id"
                :rules="{
                  required: true,
                  oneOf: getValues(formOptions.ligation_kits),
                }"
                name="ligationKit"
                label="Kit Ligación"
                class="field"
              >
                <option
                  v-for="kit in formOptions.ligation_kits"
                  :key="kit.value"
                  :value="kit.value"
                >
                  {{ kit.option }}
                </option>
              </b-select-with-validation>

              <b-select-with-validation
                v-model="formData.barcode_kit_id"
                :rules="{
                  required: true,
                  oneOf: getValues(formOptions.barcoding_kits),
                }"
                name="barcodingKit"
                label="Kit Barcode"
                class="field"
              >
                <option
                  v-for="kit in formOptions.barcoding_kits"
                  :key="kit.value"
                  :value="kit.value"
                >
                  {{ kit.option }}
                </option>
              </b-select-with-validation>

              <b-select-with-validation
                v-model="formData.operator_id"
                :rules="{
                  required: true,
                  oneOf: getValues(formOptions.operators),
                  distinct: formData.reviewer_id,
                }"
                name="operator"
                label="Operador"
                class="field"
              >
                <option
                  v-for="operator in formOptions.operators"
                  :key="operator.value"
                  :value="operator.value"
                >
                  {{ operator.option }}
                </option>
              </b-select-with-validation>

              <b-select-with-validation
                v-model="formData.reviewer_id"
                :rules="{
                  required: true,
                  oneOf: getValues(formOptions.reviewers),
                  distinct: formData.operator_id,
                }"
                name="reviewer"
                label="Revisor"
                class="field"
              >
                <option
                  v-for="reviewer in formOptions.reviewers"
                  :key="reviewer.value"
                  :value="reviewer.value"
                >
                  {{ reviewer.option }}
                </option>
              </b-select-with-validation>
            </b-field>

            <b-field grouped group-multiline class="mt-4">
              <div class="mr-3 pr-3 border-right">
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="amplificationValid"
                  :rules="{
                    required: false,
                  }"
                >
                  <b-field
                    label="Blanco amplificación válido"
                    name="amplificationValid"
                    :message="errors"
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  >
                    <div class="pt-2">
                      <b-radio
                        v-model="formData.amplification_valid"
                        :native-value="true"
                        type="is-success"
                      >
                        Si
                      </b-radio>
                      <b-radio
                        v-model="formData.amplification_valid"
                        :native-value="false"
                        type="is-danger"
                      >
                        No
                      </b-radio>
                    </div>
                  </b-field>
                </validation-provider>
              </div>
              <div class="mr-3 pr-3 border-right">
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="electroValid"
                  :rules="{
                    required: false,
                  }"
                >
                  <b-field
                    label="Electroforesis válido"
                    name="electroValid"
                    :message="errors"
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  >
                    <div class="pt-2">
                      <b-radio
                        v-model="formData.electrophoresis_valid"
                        :native-value="true"
                        type="is-success"
                      >
                        Si
                      </b-radio>
                      <b-radio
                        v-model="formData.electrophoresis_valid"
                        :native-value="false"
                        type="is-danger"
                      >
                        No
                      </b-radio>
                    </div>
                  </b-field>
                </validation-provider>
              </div>
              <div class="mr-3">
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="quantitativeControlValid"
                  :rules="{
                    required: false,
                  }"
                >
                  <b-field
                    label="Control cuantitativo válido"
                    name="quantitativeControlValid"
                    :message="errors"
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  >
                    <div class="pt-2">
                      <b-radio
                        v-model="formData.quantitative_control_valid"
                        :native-value="true"
                        type="is-success"
                      >
                        Si
                      </b-radio>
                      <b-radio
                        v-model="formData.quantitative_control_valid"
                        :native-value="false"
                        type="is-danger"
                      >
                        No
                      </b-radio>
                    </div>
                  </b-field>
                </validation-provider>
              </div>
            </b-field>

            <div class="mr-3">
              <b-input-with-validation
                v-model="formData.incidents"
                rules="max:256"
                maxlength="256"
                name="incidents"
                label="Incidentes"
                class="field"
                :debounce="500"
              />

              <b-input-with-validation
                v-model="formData.observations"
                rules="max:256"
                maxlength="256"
                name="observations"
                label="Observaciones"
                class="field"
                :debounce="500"
              />
            </div>

            <template v-if="availToUpload()">
              <validation-provider
                v-slot="{ errors, valid }"
                rules="fileSize:500|validFile"
                name="cellFile"
              >
                <label class="label">Actualizar muestras y barcodes</label>
                <b-field id="cellFile" class="file is-light" name="file">
                  <b-upload
                    v-model="formData.file"
                    name="cellFile"
                    class="file-label"
                    accept=".xls,.xlsx"
                  >
                    <span class="file-cta">
                      <b-icon class="file-icon" icon="upload"></b-icon>
                      <span> Cargar muestras (.xls, .xlsx) </span>
                    </span>
                    <span
                      v-if="formData.file"
                      class="file-name"
                      :class="valid ? 'valid-border' : 'invalid-border'"
                    >
                      {{ formData.file.name }}
                    </span>
                  </b-upload>
                </b-field>

                <span class="file-error help is-danger">{{ errors[0] }}</span>
              </validation-provider>
            </template>
          </div>
          <template v-if="submitErrors">
            <div>
              <b-message type="is-danger">
                <p><strong>Error:</strong></p>
                <p>{{ submitErrors }}</p>
              </b-message>
            </div>
          </template>
        </section>
        <footer class="modal-card-foot">
          <template v-if="!submitted">
            <b-button :disabled="saving" @click="$parent.close()">
              Cancelar
            </b-button>
            <b-button
              type="is-success"
              :loading="saving"
              @click="handleSubmit(submitChanges)"
            >
              Guardar cambios
            </b-button>
          </template>
          <template v-else>
            <b-button :disabled="saving" @click="$parent.close()">
              Cerrar
            </b-button>
          </template>
        </footer>
      </div>
    </form>
  </validation-observer>
</template>

<script>
  import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
  import BInputWithValidation from "@/components/inputs/BInputWithValidation.vue";
  import BSelectWithValidation from "@/components/inputs/BSelectWithValidation.vue";
  import { datepickerOptions } from "@/utils";
  import { routes } from "@/api";

  export default {
    name: "SeqCellEditModal",
    components: {
      ValidationObserver,
      ValidationProvider,
      BSelectWithValidation,
      BInputWithValidation,
    },
    props: {
      cellData: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        datepickerOptions,
        fileErrorMessage: "",
        selectedEquipment: this.cellData.seqEquipment,
        formData: {
          id: this.cellData.id,
          experiment_id: this.cellData.experimentId,
          cell_id: this.cellData.cellId,
          pores: this.cellData.pores,
          ligation_batch: this.cellData.ligationBatch,
          barcode_batch: this.cellData.barcodeBatch,
          observations: this.cellData.observations,
          incidents: this.cellData.incidents,
          start_datetime: new Date(this.cellData.startDatetime),
          finish_datetime: new Date(this.cellData.finishDatetime),
          operator_id: this.cellData.operatorId,
          reviewer_id: this.cellData.reviewerId,
          barcode_kit_id: this.cellData.barcodeKitId,
          ligation_kit_id: this.cellData.ligationKitId,
          sequencing_equipment_id: this.cellData.sequencingEquipmentId,
          amplification_valid: this.cellData.amplificationValid,
          electrophoresis_valid: this.cellData.electrophoresisValid,
          quantitative_control_valid: this.cellData.quantitativeControlValid,
        },
        formOptions: {
          ligation_kits: [],
          barcoding_kits: [],
          operators: [],
          reviewers: [],
          equipment: [],
        },
        loading: false,
        submitted: false,
        saving: false,
        submitErrors: null,
      };
    },
    computed: {
      equipmentTypes() {
        const equipTypes = this.formOptions.equipment.map((x) => x["type"]);
        return [...new Set(equipTypes)];
      },
      equipmentOptions() {
        const filterIds = this.formOptions.equipment.filter(
          (x) => x["type"] === this.selectedEquipment
        );
        return filterIds.map((x) => ({
          value: x["value"],
          option: x["internalId"],
        }));
      },
    },
    created() {
      this.loading = true;
      // Get form options
      this.axios
        .get(routes.getSequencingCellOptions)
        .then(({ data }) => {
          this.formOptions = data.results;
        })
        .catch(() => {
          this.alertError();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    mounted() {
      extend("unique", {
        validate: this.availCellId,
        message: "Código de celda se encuentra ingresado",
      });

      extend("validFile", {
        validate: this.validateFile,
        message: () => {
          return `Error formato archivo excel: ${this.fileErrorMessage}`;
        },
      });
    },
    methods: {
      submitChanges() {
        this.saving = true;
        let formData = this.parseFormData();
        this.axios
          .post(
            routes.updateSequencingCell.replace(/:id/, this.cellData.id),
            formData,
            { headers: { "Content-Type": "multipart/form-data" } }
          )
          .then((response) => {
            if (response.data.status === "OK") {
              this.$emit("saved", this.$parent);
            } else {
              this.submitErrors = response.data.message;
            }
            this.submitted = true;
          })
          .finally(() => {
            this.saving = false;
          });
      },
      parseFormData() {
        let formData = new FormData();
        for (let [field, value] of Object.entries(this.formData)) {
          if (value instanceof Date) {
            formData.append(
              field,
              this.$date(value).format("YYYY-MM-DD HH:mm")
            );
          } else {
            formData.append(field, value);
          }
        }
        return formData;
      },
      getValues(options) {
        return options.map((x) => x.value);
      },
      alertError() {
        this.$buefy.dialog.alert({
          title: "Error",
          message: "Error de conexión con el servidor, intente denuevo",
          type: "is-danger",
          hasIcon: true,
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      },
      availToUpload() {
        const now = this.$date();
        const recordDate = this.$date(this.cellData.recordDatetime);
        const _MS_PER_DAY = 24 * 60 * 60 * 1000;
        const dateDiff = Math.round((now - recordDate) / _MS_PER_DAY);
        return dateDiff <= 3;
      },
      async availCellId() {
        const response = await this.axios.post(
          routes.availSequencingExperimentId,
          { id_string: this.formData.experiment_id }
        );
        return !!(
          response.data.available ||
          this.cellData.experimentId === this.formData.experiment_id
        );
      },
      async validateFile() {
        let formData = new FormData();
        formData.append("file", this.formData.file);
        const response = await this.axios.post(
          routes.sequencingCellFileValidator,
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        );
        this.fileErrorMessage = response.data.message;

        return response.data.valid;
      },
    },
  };
</script>
<style scoped>
  .valid-border {
    border-color: hsl(141, 53%, 53%);
  }
  .invalid-border {
    border-color: hsl(348, 100%, 61%);
  }
  .file-name {
    border-width: 1px 1px 1px 1px;
  }
  .border-right {
    border-right: 1px solid hsl(0, 0%, 80%);
    top: 50%;
  }
</style>
