<template>
  <div class="container mt-4">
    <div class="is-centered">
      <h1 class="title">Ingreso celda de secuenciación genómica</h1>
      <p class="has-text-grey mb-3">
        En esta opción se puede registrar información acerca de celdas
        secuenciación de muestras COVID-19.
      </p>
      <p class="has-text-grey mb-3">
        Complete los campos del formulario y carge archivo excel con el listado
        de muestras dentro de la celda. Utilice la siguiente
        <a @click="getInputTemplate">[plantilla]</a> de excel como base para
        ingresar y subir la información de la celda al sistema.
      </p>
    </div>
    <div>
      <b-loading :active="loading" :is-full-page="false" />
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <form id="cellForm" @submit.prevent>
          <b-field grouped>
            <b-field expanded>
              <b-input-with-validation
                v-model="formData.experiment_id"
                rules="required|alpha_num|unique"
                name="experimentId"
                label="Número experimento"
                class="field"
                :debounce="500"
              />
            </b-field>

            <b-field expanded>
              <b-input-with-validation
                v-model="formData.cell_id"
                :rules="{ required: true, alpha_num: true }"
                name="cellId"
                label="Código celda"
                class="field"
              />
            </b-field>

            <b-field expanded>
              <b-input-with-validation
                v-model="formData.ligation_batch"
                :rules="{ required: true, regex: /^[\w.]+$/ }"
                name="ligationBatch"
                label="N°Lote Kit Ligación"
                class="field"
              />
            </b-field>

            <b-field expanded>
              <b-input-with-validation
                v-model="formData.barcode_batch"
                :rules="{ required: true, regex: /^[\w.]+$/ }"
                name="barcodeBatch"
                label="N°Lote Kit Barcode"
                class="field"
              />
            </b-field>
          </b-field>

          <b-field grouped group-multiline>
            <b-field>
              <b-input-with-validation
                v-model="formData.pores"
                :rules="{
                  required: true,
                  integer: true,
                }"
                name="pores"
                label="Número de poros activos"
                class="field"
              />
            </b-field>
            <validation-provider
              v-slot="{ errors, valid }"
              name="startDatetime"
              :rules="{
                required: true,
              }"
            >
              <b-field
                label="Fecha y hora de inicio"
                name="startDatetime"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :message="errors"
              >
                <b-datetimepicker
                  v-model="formData.start_datetime"
                  placeholder="DD-MM-AAAA HH:SS"
                  icon="calendar-today"
                  :datepicker="datepickerOptions"
                  :timepicker="{ hourFormat: '24' }"
                  append-to-body
                  class="field"
                />
              </b-field>
            </validation-provider>

            <validation-provider
              v-slot="{ errors, valid }"
              name="finishDatetime"
              :rules="{
                required: true,
              }"
            >
              <b-field
                label="Fecha y hora de término"
                name="finishDatetime"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :message="errors"
              >
                <b-datetimepicker
                  v-model="formData.finish_datetime"
                  placeholder="DD-MM-AAAA HH:SS"
                  icon="calendar-today"
                  :datepicker="datepickerOptions"
                  :timepicker="{ hourFormat: '24' }"
                  append-to-body
                  class="field"
                />
              </b-field>
            </validation-provider>
          </b-field>

          <b-field grouped group-multiline>
            <b-select-with-validation
              v-model="selectedEquipment"
              :rules="{
                required: true,
                oneOf: equipmentTypes,
              }"
              name="equipmentType"
              label="Equipo"
              class="field"
            >
              <option
                v-for="equipment in equipmentTypes"
                :key="equipment"
                :value="equipment"
              >
                {{ equipment }}
              </option>
            </b-select-with-validation>

            <b-select-with-validation
              v-model="formData.sequencing_equipment_id"
              :rules="{
                required: true,
                oneOf: getValues(equipmentOptions),
              }"
              name="equipmentId"
              label="Código Equipo"
              class="field"
            >
              <option
                v-for="equipment in equipmentOptions"
                :key="equipment.value"
                :value="equipment.value"
              >
                {{ equipment.option }}
              </option>
            </b-select-with-validation>

            <b-select-with-validation
              v-model="formData.ligation_kit_id"
              :rules="{
                required: true,
                oneOf: getValues(formOptions.ligation_kits),
              }"
              name="ligationKit"
              label="Kit Ligación"
              class="field"
            >
              <option
                v-for="kit in formOptions.ligation_kits"
                :key="kit.value"
                :value="kit.value"
              >
                {{ kit.option }}
              </option>
            </b-select-with-validation>

            <b-select-with-validation
              v-model="formData.barcode_kit_id"
              :rules="{
                required: true,
                oneOf: getValues(formOptions.barcoding_kits),
              }"
              name="barcodingKit"
              label="Kit Barcode"
              class="field"
            >
              <option
                v-for="kit in formOptions.barcoding_kits"
                :key="kit.value"
                :value="kit.value"
              >
                {{ kit.option }}
              </option>
            </b-select-with-validation>

            <b-select-with-validation
              v-model="formData.operator_id"
              :rules="{
                required: true,
                oneOf: getValues(formOptions.operators),
                distinct: formData.reviewer_id,
              }"
              name="operator"
              label="Operador"
              class="field"
            >
              <option
                v-for="operator in formOptions.operators"
                :key="operator.value"
                :value="operator.value"
              >
                {{ operator.option }}
              </option>
            </b-select-with-validation>

            <b-select-with-validation
              v-model="formData.reviewer_id"
              :rules="{
                required: true,
                oneOf: getValues(formOptions.reviewers),
                distinct: formData.operator_id,
              }"
              name="reviewer"
              label="Revisor"
              class="field"
            >
              <option
                v-for="reviewer in formOptions.reviewers"
                :key="reviewer.value"
                :value="reviewer.value"
              >
                {{ reviewer.option }}
              </option>
            </b-select-with-validation>
            <div class="ml-2">
              <validation-provider
                v-slot="{ errors, valid }"
                name="incidents"
                :rules="{
                  required: true,
                }"
              >
                <b-field
                  label="Incidentes"
                  name="inicidents"
                  :message="errors"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                >
                  <div class="pt-2">
                    <b-radio
                      v-model="hasIncidents"
                      :native-value="true"
                      type="is-danger"
                    >
                      Si
                    </b-radio>
                    <b-radio
                      v-model="hasIncidents"
                      :native-value="false"
                      type="is-info"
                    >
                      No
                    </b-radio>
                  </div>
                </b-field>
              </validation-provider>
            </div>
          </b-field>

          <b-field grouped group-multiline class="mt-4">
            <div class="mr-3 pr-3 border-right">
              <validation-provider
                v-slot="{ errors, valid }"
                name="amplificationValid"
                :rules="{
                  required: false,
                }"
              >
                <b-field
                  label="Blanco amplificación válido"
                  name="amplificationValid"
                  :message="errors"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                >
                  <div class="pt-2">
                    <b-radio
                      v-model="formData.amplification_valid"
                      :native-value="true"
                      type="is-success"
                    >
                      Si
                    </b-radio>
                    <b-radio
                      v-model="formData.amplification_valid"
                      :native-value="false"
                      type="is-danger"
                    >
                      No
                    </b-radio>
                  </div>
                </b-field>
              </validation-provider>
            </div>
            <div class="mr-3 pr-3 border-right">
              <validation-provider
                v-slot="{ errors, valid }"
                name="electroValid"
                :rules="{
                  required: false,
                }"
              >
                <b-field
                  label="Electroforesis válido"
                  name="electroValid"
                  :message="errors"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                >
                  <div class="pt-2">
                    <b-radio
                      v-model="formData.electrophoresis_valid"
                      :native-value="true"
                      type="is-success"
                    >
                      Si
                    </b-radio>
                    <b-radio
                      v-model="formData.electrophoresis_valid"
                      :native-value="false"
                      type="is-danger"
                    >
                      No
                    </b-radio>
                  </div>
                </b-field>
              </validation-provider>
            </div>
            <div class="mr-3">
              <validation-provider
                v-slot="{ errors, valid }"
                name="quantitativeControlValid"
                :rules="{
                  required: false,
                }"
              >
                <b-field
                  label="Control cuantitativo válido"
                  name="quantitativeControlValid"
                  :message="errors"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                >
                  <div class="pt-2">
                    <b-radio
                      v-model="formData.quantitative_control_valid"
                      :native-value="true"
                      type="is-success"
                    >
                      Si
                    </b-radio>
                    <b-radio
                      v-model="formData.quantitative_control_valid"
                      :native-value="false"
                      type="is-danger"
                    >
                      No
                    </b-radio>
                  </div>
                </b-field>
              </validation-provider>
            </div>
          </b-field>

          <div class="mr-3">
            <b-input-with-validation
              v-if="hasIncidents"
              v-model="formData.incidents"
              rules="max:256|required"
              maxlength="256"
              name="incidents"
              label="Incidentes"
              class="field"
              :debounce="500"
            />

            <b-input-with-validation
              v-model="formData.observations"
              type="textarea"
              rules="max:256"
              maxlength="256"
              name="observations"
              label="Observaciones"
              class="field"
              :debounce="500"
            />
          </div>

          <validation-provider
            v-slot="{ errors, valid }"
            rules="required|fileSize:500|validFile"
            name="cellFile"
          >
            <label class="label">Archivo</label>
            <b-field id="cellFile" class="file is-light" name="file" grouped>
              <b-upload
                v-model="formData.file"
                name="cellFile"
                class="file-label"
                accept=".xls,.xlsx"
                @input="validateFile"
              >
                <span class="file-cta">
                  <b-icon class="file-icon" icon="upload"></b-icon>
                  <span> Cargar muestras (.xls, .xlsx) </span>
                </span>
                <span
                  v-if="formData.file"
                  class="file-name"
                  :class="valid ? 'valid-border' : 'invalid-border'"
                >
                  {{ formData.file.name }}
                </span>
              </b-upload>
              <p v-if="formData.file !== null" class="control">
                <b-button
                  class="button is-danger is-outlined"
                  icon-left="close"
                  @click="formData.file = null"
                />
              </p>
            </b-field>

            <span class="file-error help is-danger">{{ errors[0] }}</span>
            <div v-if="loadingLayout" class="loading-container">
              <b-loading :active="loadingLayout" :is-full-page="false" />
            </div>
            <template v-if="valid && cellData">
              <h1 class="title small">Mapeo por cargar</h1>
              <p class="has-text-grey mb-3">
                En la siguiente tabla se puede visualizar las muestras dentro de
                la celda a ingresar. En verde se encuentra el número de muestra
                y en azul su barcode.
              </p>
              <sample-sequencing-table
                class="mt-4 mb-4"
                :table-data="cellData"
              />
            </template>
          </validation-provider>
          <hr />

          <p class="control">
            <b-button
              :loading="submitLoading"
              class="button is-primary"
              @click="handleSubmit(submit)"
            >
              Guardar
            </b-button>
          </p>
        </form>
      </validation-observer>

      <template v-if="!submitSuccess">
        <b-message
          id="message"
          title="Error"
          type="is-danger"
          aria-close-label="Close message"
        >
          <p v-for="(message, i) in submitMessage" :key="i">
            {{ message }}
          </p>
        </b-message>
      </template>
    </div>
  </div>
</template>

<script>
  import BInputWithValidation from "@/components/inputs/BInputWithValidation.vue";
  import BSelectWithValidation from "@/components/inputs/BSelectWithValidation.vue";
  import SampleSequencingTable from "@/components/sequencing/SampleSequencingTable.vue";
  import { datepickerOptions } from "@/utils";
  import { routes } from "@/api";
  import { saveAs } from "file-saver";
  import { extend, ValidationObserver, ValidationProvider } from "vee-validate";

  export default {
    name: "SeqCellInput",
    components: {
      BInputWithValidation,
      BSelectWithValidation,
      ValidationProvider,
      ValidationObserver,
      SampleSequencingTable,
    },
    data() {
      return {
        datepickerOptions,
        submitLoading: false,
        submitMessage: null,
        submitSuccess: true,
        hasIncidents: false,
        loading: false,
        loadingLayout: false,
        cellData: null,
        selectedEquipment: "minION",
        formData: {
          experiment_id: "",
          ligation_batch: "",
          barcode_batch: "",
          observations: "",
          incidents: "",
          pores: "",
          start_datetime: null,
          finish_datetime: null,
          operator_id: "",
          reviewer_id: "",
          ligation_kit_id: "",
          barcode_kit_id: "",
          sequencing_equipment_id: "",
          amplification_valid: "",
          electrophoresis_valid: "",
          quantitative_control_valid: "",
          file: null,
        },
        formOptions: {
          ligation_kits: [],
          barcoding_kits: [],
          operators: [],
          reviewers: [],
          equipment: [],
        },
        fileErrorMessage: "",
      };
    },
    computed: {
      equipmentTypes() {
        const equipTypes = this.formOptions.equipment.map((x) => x["type"]);
        return [...new Set(equipTypes)];
      },
      equipmentOptions() {
        const filterIds = this.formOptions.equipment.filter(
          (x) => x["type"] === this.selectedEquipment
        );
        return filterIds.map((x) => ({
          value: x["value"],
          option: x["internalId"],
        }));
      },
    },
    created() {
      this.getFormOptions();
    },
    mounted() {
      extend("unique", {
        validate: this.availExperimentId,
        message: "Código de celda se encuentra ingresado",
      });

      extend("validFile", {
        validate: this.validateFile,
        message: () => {
          return `Error formato archivo excel: ${this.fileErrorMessage}`;
        },
      });
    },
    methods: {
      async submit() {
        this.submitLoading = true;
        this.submitSuccess = true;
        let formData = this.parseFormData();
        try {
          const response = await this.axios.post(
            routes.inputSequencingCellLayout,
            formData,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          );

          if (response.data.status === "error") {
            this.$buefy.toast.open({
              duration: 2000,
              message: "Datos ingresados",
            });
            this.submitSuccess = false;
            this.submitMessage = response.data.message.error;
          } else {
            this.$buefy.toast.open({
              duration: 2000,
              message: "Datos ingresados",
              type: "is-success",
            });
            this.clearForm();
          }
        } catch (e) {
          this.submitSuccess = false;
          this.submitMessage = "Error de conexión con el servidor";
        }

        this.submitLoading = false;
      },
      parseFormData() {
        let formData = new FormData();
        for (let [field, value] of Object.entries(this.formData)) {
          if (value instanceof Date) {
            formData.append(
              field,
              this.$date(value).format("YYYY-MM-DD HH:mm")
            );
          } else {
            formData.append(field, value);
          }
        }
        return formData;
      },
      getFormOptions() {
        this.loading = true;
        this.axios
          .get(routes.getSequencingCellOptions)
          .then((response) => {
            this.formOptions = response.data.results;
          })
          .catch(() => {
            this.alertError();
          })
          .finally(() => {
            this.loading = false;
          });
      },
      getValues(options) {
        return options.map((x) => x.value);
      },
      clearForm() {
        this.formData = {
          experiment_id: "",
          ligation_batch: "",
          barcode_batch: "",
          observations: "",
          incidents: "",
          pores: "",
          start_datetime: null,
          finish_datetime: null,
          operator_id: "",
          reviewer_id: "",
          ligation_kit_id: "",
          barcode_kit_id: "",
          sequencing_equipment_id: "",
          amplification_valid: "",
          electrophoresis_valid: "",
          quantitative_control_valid: "",
          file: null,
        };
        this.$nextTick(() => this.$refs.observer.reset());
      },
      async availExperimentId() {
        const response = await this.axios.post(
          routes.availSequencingExperimentId,
          { id_string: this.formData.experiment_id }
        );
        return response.data.available;
      },
      async validateFile() {
        this.cellData = null;
        this.loadingLayout = true;
        let formData = new FormData();
        formData.append("file", this.formData.file);
        const response = await this.axios.post(
          routes.sequencingCellFileValidator,
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        );
        this.cellData = response.data.parseData;
        this.fileErrorMessage = response.data.message;
        this.loadingLayout = false;

        return response.data.valid;
      },
      alertError() {
        this.$buefy.dialog.alert({
          title: "Error",
          message: "Error de conexión con el servidor, intente denuevo",
          type: "is-danger",
          hasIcon: true,
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      },
      getInputTemplate() {
        this.axios
          .get(routes.seqCellInputTemplate, {
            responseType: "blob",
          })
          .then((response) => {
            const blob = new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            saveAs(blob, `CADI_plantilla_ingreso_secuenciación.xlsx`);
          })
          .catch(() => {
            this.errorAlert("Error al descargar datos, intente denuevo");
          });
      },
    },
  };
</script>

<style scoped>
  hr {
    margin-top: 0.7rem;
    margin-bottom: 0.7rem;
  }
  #message {
    margin-top: 0.7rem;
  }
  .small {
    font-size: 1.3rem;
    margin-top: 1.5rem;
  }
  .valid-border {
    border-color: hsl(141, 53%, 53%);
  }
  .invalid-border {
    border-color: hsl(348, 100%, 61%);
  }
  .file-name {
    border-width: 1px 1px 1px 1px;
  }
  .border-right {
    border-right: 1px solid hsl(0, 0%, 80%);
    top: 50%;
  }
  .loading-container {
    position: relative;
    height: 100px;
  }
</style>
