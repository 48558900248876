<template>
  <div>
    <div class="container mt-5">
      <h1 class="title">Muestras Procesadas</h1>

      <p class="has-text-grey">
        Esta pantalla permite visualizar las muestras procesadas y validadas
        hasta la fecha.<br />
        Las muestras se pueden seleccionar dependiendo de un rango de fechas de
        análisis definido.
      </p>
      <section class="mt-4">
        <b-field grouped position="is-centered">
          <b-field label="Búsqueda" position="is-centered">
            <b-switch v-model="searchable" size="is-medium" :rounded="false">
            </b-switch>
          </b-field>
          <b-field label="Desde">
            <b-datetimepicker
              v-model="datetimeFrom"
              icon="calendar-today"
              :datepicker="datepickerOptions"
              :timepicker="{ hourFormat: '24' }"
              :disabled="loading"
            />
          </b-field>
          <b-field label="Hasta">
            <b-datetimepicker
              v-model="datetimeTo"
              icon="calendar-today"
              :datepicker="datepickerOptions"
              :timepicker="{ hourFormat: '24' }"
              :disabled="loading"
            />
          </b-field>
          <b-field>
            <template slot="label"><br /></template>
            <b-button type="is-primary" :disabled="loading" @click="loadData">
              Filtrar
            </b-button>
          </b-field>
        </b-field>
      </section>
    </div>
    <div
      v-if="loading || resultsData.length > 0"
      class="container is-fluid mt-4"
    >
      <div class="table-container">
        <b-table
          :key="resultsFields.join()"
          :data="resultsData"
          :loading="loading"
          narrowed
        >
          <b-table-column v-slot="props" label="#">
            {{ props.index + 1 }}
          </b-table-column>

          <b-table-column
            v-slot="props"
            field="sampleId"
            :label="tableLabels.sampleId"
            centered
            sortable
            numeric
            :searchable="searchable"
          >
            {{ props.row.sampleId }}
            <span v-if="props.row.poolId">
              [{{ formatPoolId(props.row.poolId) }}]
            </span>
          </b-table-column>

          <b-table-column
            v-slot="props"
            field="externalId"
            :label="tableLabels.externalId"
            centered
            sortable
            numeric
            :searchable="searchable"
          >
            {{ props.row.externalId }}
          </b-table-column>

          <b-table-column
            v-slot="props"
            field="institution"
            :label="tableLabels.institution"
            centered
            :searchable="searchable"
          >
            {{ props.row.institution }}
          </b-table-column>

          <b-table-column
            v-slot="props"
            field="receptionDatetime"
            :label="tableLabels.receptionDatetime"
            centered
            sortable
          >
            {{ formatDateTime(props.row.receptionDatetime) }}
          </b-table-column>

          <b-table-column
            v-for="field in resultsFields"
            v-slot="props"
            :key="field"
            :label="field"
            centered
          >
            <span :class="getValueTextClass(field, props.row.ctValues[field])">
              {{ formatFloat(props.row.ctValues[field]) }}
            </span>
          </b-table-column>

          <b-table-column
            v-slot="props"
            field="observations"
            label="Observaciones"
            centered
          >
            {{ props.row.observations }}
          </b-table-column>

          <b-table-column
            v-slot="props"
            field="conclusion"
            label="Conclusión"
            centered
            sortable
            :searchable="searchable"
          >
            <span
              v-if="props.row.validationDatetime"
              class="is-uppercase"
              :class="getColorValidation(props.row.conclusion)"
            >
              {{ props.row.conclusion !== "" ? props.row.conclusion : "--" }}
            </span>
          </b-table-column>

          <b-table-column
            v-slot="props"
            field="validationDatetime"
            label="Validación"
            centered
            sortable
          >
            {{ formatDateTime(props.row.validationDatetime) }}
          </b-table-column>

          <b-table-column
            v-slot="props"
            field="analyst"
            label="Analista"
            centered
            sortable
          >
            {{ props.row.analyst }}
          </b-table-column>
          <b-table-column
            v-slot="props"
            :visible="anySampleWithRepetition"
            field="repetition"
            label="Repetición"
            centered
            sortable
          >
            {{ props.row.repetitionStatus }}
          </b-table-column>
          <template slot="empty"><div class="table-empty" /></template>
        </b-table>
      </div>
      <section
        v-if="resultsData && resultsData.length > 0"
        class="mt-5 has-text-centered"
      >
        <b-field>
          <b-button class="is-primary" icon-left="content-save">
            <download-csv
              :data="formattedData"
              :separator-excel="excelCompatible"
              :fields="Object.values(tableLabels)"
              :name="`all_samples_${$date().format('YYYYMMDD')}.csv`"
            >
              Descargar (.csv)
            </download-csv>
          </b-button>
        </b-field>
        <b-field>
          <b-checkbox v-model="excelCompatible">
            Hacer compatible con MS Excel
          </b-checkbox>
        </b-field>
      </section>
    </div>
    <div
      v-else-if="Object.keys(cutoffs).length > 0 && resultsData.length === 0"
      class="container mt-5"
    >
      <b-message>
        No se encontraron resultados en el rango de fechas indicado.
      </b-message>
    </div>
  </div>
</template>

<script>
  import { routes } from "@/api";
  import { datepickerOptions, formatDateTime, validate } from "@/utils";

  export default {
    name: "ResultsReview",
    data() {
      return {
        loading: false,
        resultsData: [],
        targetsInfo: [],
        searchable: false,
        datepickerOptions,
        datetimeFrom: this.$date().hour(0).minute(0).toDate(),
        datetimeTo: this.$date().hour(23).minute(59).toDate(),
        excelCompatible: false,
        tableLabels: {
          sampleId: "Muestra",
          externalId: "N° Pet. (Ex)",
          institution: "Institución",
          idString: "RUN",
          receptionDatetime: "Recepción",
          observations: "Observaciones",
          conclusion: "Conclusión",
          validationDatetime: "Fecha de validación",
          analyst: "Analista",
          repetitionStatus: "Repetición",
        },
      };
    },
    computed: {
      formattedData() {
        return this.resultsData.map((x) => {
          return Object.entries(x).reduce((o, [k, v]) => {
            const label = Object.keys(this.tableLabels).includes(k)
              ? this.tableLabels[k]
              : k;

            o[label] = k === "date" ? this.$date(v).format("DD-MM-YYYY") : v;
            return o;
          }, {});
        });
      },
      anySampleWithRepetition() {
        return this.resultsData.some((x) => x.repetitionStatus !== "");
      },
      cutoffs() {
        return this.targetsInfo.reduce(
          (o, v) => ({ ...o, [v.name.replace(" ", "")]: v.cutoffs }),
          {}
        );
      },
      allTargetNames() {
        return this.targetsInfo.map((x) => x.name.replace(" ", ""));
      },
      resultsFields() {
        const resultFields = [
          ...new Set(
            Object.values(this.resultsData)
              .map((x) => Object.keys(x.ctValues))
              .flat()
          ),
        ];

        return resultFields.sort((a, b) => {
          const score_a = this.getPositionScore(a);
          const score_b = this.getPositionScore(b);

          if (score_a > score_b) {
            return 1;
          } else if (score_b > score_a) {
            return -1;
          } else {
            return 0;
          }
        });
      },
    },

    created() {
      this.loadData();
    },
    methods: {
      loadData() {
        this.loading = true;
        this.axios
          .get(routes.visualizationList, {
            params: {
              datetime_from: this.$date(this.datetimeFrom).format(
                "YYYY-MM-DD HH:mm"
              ),
              datetime_to: this.$date(this.datetimeTo).format(
                "YYYY-MM-DD HH:mm"
              ),
            },
          })
          .then((response) => {
            this.resultsData = response.data.results;
            this.targetsInfo = response.data.targetsInfo;
          })
          .catch((e) => {
            this.$log.error(e);
          })
          .finally(() => {
            this.loading = false;
          });
      },
      formatFloat(value) {
        if (!isNaN(value) && value !== null) {
          return value.toFixed(2);
        } else {
          return "--";
        }
      },
      formatDateTime(datetime) {
        return formatDateTime(datetime);
      },
      validate(target, value) {
        return validate(target, value, this.cutoffs);
      },
      getValueTextClass(target, value) {
        return this.validate(target, value)
          ? "has-text-success"
          : "has-text-danger";
      },
      getColorValidation(valid) {
        if (valid === "POSITIVO") return "has-text-danger";
      },
      getPositionScore(target) {
        if (target.includes("_")) {
          const tSplit = target.split("_");

          return (
            this.allTargetNames.indexOf(tSplit[0]) * 10 +
            this.allTargetNames.indexOf(tSplit[1])
          );
        } else {
          return this.allTargetNames.indexOf(target) * 10;
        }
      },
      formatPoolId(id) {
        return process.env.VITE_POOL_PREFIX + id.toString().padStart(3, "0");
      },
    },
  };
</script>

<style scoped lang="scss">
  @import "../../assets/scss/variables";

  .has-text-danger {
    color: $danger;
  }

  .has-text-success {
    color: $success;
  }
</style>
