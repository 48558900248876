<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <form @submit.prevent>
      <div class="modal-card" style="width: auto">
        <b-loading :active="loading" :is-full-page="false" />

        <header class="modal-card-head">
          <p class="modal-card-title">
            Modificar registro Nº{{ plateData.id }}
          </p>
        </header>
        <section class="modal-card-body">
          <div class="mb-3">
            <b-field grouped>
              <b-field expanded>
                <b-input-with-validation
                  v-model="formData.id_string"
                  rules="required|alpha_num|unique"
                  name="idString"
                  label="Código placa"
                  class="field"
                  :debounce="500"
                />
              </b-field>
              <b-field expanded>
                <b-input-with-validation
                  v-model="formData.batch"
                  rules="required|alpha_num"
                  name="batch"
                  label="Lote"
                  class="field"
                />
              </b-field>
              <b-field expanded>
                <b-select-with-validation
                  v-model="formData.operator_id"
                  :rules="{
                    required: true,
                    oneOf: getValues(formOptions.users),
                  }"
                  name="operator"
                  label="Operador"
                  class="field"
                >
                  <option
                    v-for="user in formOptions.users"
                    :key="user.value"
                    :value="user.value"
                  >
                    {{ user.option }}
                  </option>
                </b-select-with-validation>
              </b-field>
              <b-field expanded>
                <b-select-with-validation
                  v-model="formData.reviewer_id"
                  :rules="{
                    required: true,
                    oneOf: getValues(formOptions.users),
                  }"
                  name="reviewer"
                  label="Revisor"
                  class="field"
                >
                  <option
                    v-for="user in formOptions.users"
                    :key="user.value"
                    :value="user.value"
                  >
                    {{ user.option }}
                  </option>
                </b-select-with-validation>
              </b-field>
            </b-field>

            <b-field grouped>
              <b-field expanded>
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="startDatetime"
                  :rules="{
                    required: true,
                  }"
                >
                  <b-field
                    label="Fecha y hora de inicio"
                    name="startDatetime"
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                    :message="errors"
                  >
                    <b-datetimepicker
                      v-model="formData.start_datetime"
                      placeholder="DD-MM-AAAA HH:SS"
                      icon="calendar-today"
                      :datepicker="datepickerOptions"
                      :timepicker="{ hourFormat: '24' }"
                      append-to-body
                      class="field"
                    />
                  </b-field>
                </validation-provider>
              </b-field>
              <b-field expanded>
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="finishDatetime"
                  :rules="{
                    required: true,
                  }"
                >
                  <b-field
                    label="Fecha y hora de término"
                    name="finishDatetime"
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                    :message="errors"
                  >
                    <b-datetimepicker
                      v-model="formData.finish_datetime"
                      placeholder="DD-MM-AAAA HH:SS"
                      icon="calendar-today"
                      :datepicker="datepickerOptions"
                      :timepicker="{ hourFormat: '24' }"
                      append-to-body
                      class="field"
                    />
                  </b-field>
                </validation-provider>
              </b-field>
              <b-field expanded>
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="expirationDate"
                  :rules="{
                    required: true,
                  }"
                >
                  <b-field
                    label="Fecha vencimiento"
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                    :message="errors"
                  >
                    <b-datepicker
                      v-model="formData.expiration_datetime"
                      placeholder="DD-MM-AAAA"
                      icon="calendar-today"
                      :first-day-of-week="datepickerOptions.firstDayOfWeek"
                      :day-names="datepickerOptions.dayNames"
                      append-to-body
                      class="field"
                    />
                  </b-field>
                </validation-provider>
              </b-field>
            </b-field>

            <b-field grouped>
              <b-select-with-validation
                v-model="formData.pcr_equipment_id"
                :rules="{
                  required: true,
                  oneOf: getValues(formOptions.equipment),
                }"
                name="equipment"
                label="Equipo"
                class="field"
              >
                <option
                  v-for="equipment in formOptions.equipment"
                  :key="equipment.value"
                  :value="equipment.value"
                >
                  {{ equipment.option }}
                </option>
              </b-select-with-validation>
              <b-select-with-validation
                v-model="formData.pcr_kit_id"
                :rules="{ required: true, oneOf: getValues(formOptions.kits) }"
                name="kit"
                label="Kit"
                class="field"
              >
                <option
                  v-for="kit in formOptions.kits"
                  :key="kit.value"
                  :value="kit.value"
                >
                  {{ kit.option }}
                </option>
              </b-select-with-validation>
              <div class="mr-3 pr-3 border-right">
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="ntcControl"
                  :rules="{
                    required: true,
                  }"
                >
                  <b-field
                    label="NTC válido"
                    name="ntcControl"
                    :message="errors"
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  >
                    <div class="pt-2">
                      <b-radio
                        v-model="formData.ntc_valid"
                        :native-value="true"
                        type="is-success"
                      >
                        Si
                      </b-radio>
                      <b-radio
                        v-model="formData.ntc_valid"
                        :native-value="false"
                        type="is-danger"
                      >
                        No
                      </b-radio>
                    </div>
                  </b-field>
                </validation-provider>
              </div>
              <div class="mr-3 pr-3 border-right">
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="positiveControl"
                  :rules="{
                    required: true,
                  }"
                >
                  <b-field
                    label="Control positivo válido"
                    name="positiveControl"
                    :message="errors"
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  >
                    <div class="pt-2">
                      <b-radio
                        v-model="formData.positive_control_valid"
                        :native-value="true"
                        type="is-success"
                      >
                        Si
                      </b-radio>
                      <b-radio
                        v-model="formData.positive_control_valid"
                        :native-value="false"
                        type="is-danger"
                      >
                        No
                      </b-radio>
                    </div>
                  </b-field>
                </validation-provider>
              </div>
              <div class="mr-3">
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="negativeControl"
                  :rules="{
                    required: true,
                  }"
                >
                  <b-field
                    label="Control negativo válido"
                    name="negativeControl"
                    :message="errors"
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  >
                    <div class="pt-2">
                      <b-radio
                        v-model="formData.negative_control_valid"
                        :native-value="true"
                        type="is-success"
                      >
                        Si
                      </b-radio>
                      <b-radio
                        v-model="formData.negative_control_valid"
                        :native-value="false"
                        type="is-danger"
                      >
                        No
                      </b-radio>
                    </div>
                  </b-field>
                </validation-provider>
              </div>
            </b-field>

            <b-field expanded>
              <b-input-with-validation
                v-model="formData.observations"
                rules="max:256"
                maxlength="256"
                name="observations"
                label="Observaciones"
                class="field"
                :debounce="500"
              />
            </b-field>
            <b-field expanded>
              <b-input-with-validation
                v-model="formData.incidents"
                rules="max:256"
                maxlength="256"
                name="incidents"
                label="Incidentes"
                class="field"
                :debounce="500"
              />
            </b-field>
            <template v-if="availToUpload()">
              <validation-provider
                v-slot="{ errors, valid }"
                rules="fileSize:500|validFile"
                name="plateFile"
              >
                <label class="label">Actualizar mapeo</label>
                <b-field id="plateFile" class="file is-light" name="file">
                  <b-upload
                    v-model="formData.file"
                    name="plateFile"
                    class="file-label"
                    accept=".xls,.xlsx"
                  >
                    <span class="file-cta">
                      <b-icon class="file-icon" icon="upload"></b-icon>
                      <span> Cargar layout (.xls, .xlsx) </span>
                    </span>
                    <span
                      v-if="formData.file"
                      class="file-name"
                      :class="valid ? 'valid-border' : 'invalid-border'"
                    >
                      {{ formData.file.name }}
                    </span>
                  </b-upload>
                </b-field>

                <span class="file-error help is-danger">{{ errors[0] }}</span>
              </validation-provider>
            </template>
          </div>
          <template v-if="submitErrors">
            <div>
              <b-message type="is-danger">
                <p><strong>Error:</strong></p>
                <p>{{ submitErrors }}</p>
              </b-message>
            </div>
          </template>
        </section>
        <footer class="modal-card-foot">
          <template v-if="!submitted">
            <b-button :disabled="saving" @click="$parent.close()">
              Cancelar
            </b-button>
            <b-button
              type="is-success"
              :loading="saving"
              @click="handleSubmit(submitChanges)"
            >
              Guardar cambios
            </b-button>
          </template>
          <template v-else>
            <b-button :disabled="saving" @click="$parent.close()">
              Cerrar
            </b-button>
          </template>
        </footer>
      </div>
    </form>
  </validation-observer>
</template>

<script>
  import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
  import BInputWithValidation from "@/components/inputs/BInputWithValidation.vue";
  import BSelectWithValidation from "@/components/inputs/BSelectWithValidation.vue";
  import { datepickerOptions } from "@/utils";
  import { routes } from "@/api";

  export default {
    name: "PcrPlateEditModal",
    components: {
      ValidationObserver,
      ValidationProvider,
      BSelectWithValidation,
      BInputWithValidation,
    },
    props: {
      plateData: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        datepickerOptions,
        fileErrorMessage: "",
        formData: {
          id: this.plateData.id,
          id_string: this.plateData.idString,
          batch: this.plateData.batch,
          observations: this.plateData.observations,
          incidents: this.plateData.incidents,
          start_datetime: new Date(this.plateData.startDatetime),
          finish_datetime: new Date(this.plateData.finishDatetime),
          expiration_datetime: new Date(this.plateData.expirationDatetime),
          operator_id: this.plateData.operatorId,
          reviewer_id: this.plateData.reviewerId,
          pcr_kit_id: this.plateData.pcrKitId,
          pcr_equipment_id: this.plateData.pcrEquipmentId,
          positive_control_valid: this.plateData.positiveControlValid,
          negative_control_valid: this.plateData.negativeControlValid,
          ntc_valid: this.plateData.ntcValid,
        },
        formOptions: {
          kits: [],
          users: [],
          controls: [],
          equipment: [],
        },
        loading: false,
        submitted: false,
        saving: false,
        submitErrors: null,
      };
    },
    created() {
      this.loading = true;
      // Get form options
      this.axios
        .get(routes.getPcrPlateOptions)
        .then(({ data }) => {
          this.formOptions = data.results;
        })
        .catch(() => {
          this.alertError();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    mounted() {
      extend("unique", {
        validate: this.availPlateId,
        message: "Código de placa se encuentra ingresado",
      });

      extend("validFile", {
        validate: this.validateFile,
        message: () => {
          return `Error formato archivo excel: ${this.fileErrorMessage}`;
        },
      });
    },
    methods: {
      submitChanges() {
        this.saving = true;
        let formData = this.parseFormData();
        this.axios
          .post(
            routes.updatePcrPlate.replace(/:id/, this.plateData.id),
            formData,
            { headers: { "Content-Type": "multipart/form-data" } }
          )
          .then((response) => {
            if (response.data.status === "OK") {
              this.$emit("saved", this.$parent);
            } else {
              this.submitErrors = response.data.message;
            }
            this.submitted = true;
          })
          .finally(() => {
            this.saving = false;
          });
      },
      parseFormData() {
        let formData = new FormData();
        for (let [field, value] of Object.entries(this.formData)) {
          if (value instanceof Date) {
            formData.append(
              field,
              this.$date(value).format("YYYY-MM-DD HH:mm")
            );
          } else {
            formData.append(field, value);
          }
        }
        return formData;
      },
      getValues(options) {
        return options.map((x) => x.value);
      },
      alertError() {
        this.$buefy.dialog.alert({
          title: "Error",
          message: "Error de conexión con el servidor, intente denuevo",
          type: "is-danger",
          hasIcon: true,
          ariaRole: "alertdialog",
          ariaModal: true,
        });
      },
      availToUpload() {
        // for now
        return true;

        const now = this.$date();
        const recordDate = this.$date(this.plateData.recordDatetime);
        const _MS_PER_DAY = 24 * 60 * 60 * 1000;
        const dateDiff = Math.round((now - recordDate) / _MS_PER_DAY);
        return dateDiff <= 3;
      },
      async availPlateId() {
        const response = await this.axios.get(
          routes.availPcrPlateId.replace(":id", String(this.formData.id_string))
        );
        return !!(
          response.data.available ||
          this.plateData.idString === this.formData.id_string
        );
      },
      async validateFile() {
        let formData = new FormData();
        formData.append("file", this.formData.file);
        const response = await this.axios.post(
          routes.standardPlateFileValidator,
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        );
        this.fileErrorMessage = response.data.message;

        return response.data.valid;
      },
    },
  };
</script>
<style scoped>
  .valid-border {
    border-color: hsl(141, 53%, 53%);
  }
  .invalid-border {
    border-color: hsl(348, 100%, 61%);
  }
  .file-name {
    border-width: 1px 1px 1px 1px;
  }
  .border-right {
    border-right: 1px solid hsl(0, 0%, 80%);
    top: 50%;
  }
</style>
